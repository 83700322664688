// src/components/Papercut/ActivePapercut.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const ActivePapercut = ({ name }) => (
  <Box 
    sx={{ 
      py: 1.5,
      px: 2,
      borderBottom: 1,
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center',
      bgcolor: 'background.default',
      justifyContent: 'space-between',
      height: '40px', // Match the height of FileCount
    }}
  >
    <Typography
      variant="body2"
      color="text.primary"
      sx={{ 
        fontWeight: 500,
        fontSize: '0.75rem',
      }}
    >
      {name || 'No Papercuts'}
    </Typography>
    {/* Chevron and IconButton removed */}
  </Box>
);

export default ActivePapercut;
