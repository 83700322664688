import React, { useState } from 'react';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline, Snackbar, Alert } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';
import ProtectedApp from './components/Layout/ProtectedApp';
import SignUpPage from './components/Pages/SignUpPage';

// Pages not handled by the main layout
import SearchPage from './components/Pages/SearchPage';
import NotificationsPage from './components/Pages/NotificationsPage';
import ProfilePage from './components/Pages/ProfilePage';
import SettingsPage from './components/Pages/SettingsPage';
import BillingPage from './components/Pages/BillingPage';
import SupportPage from './components/Pages/SupportPage';

const App = () => {
  const [themeMode, setThemeMode] = useState('dark');
  const [notification, setNotification] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [selectedBinClip, setSelectedBinClip] = useState(null);
  const [transcripts, setTranscripts] = useState(new Map());
  const [currentView, setCurrentView] = useState('editor');

  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: { main: '#0ea5e9' },
      background: {
        default: themeMode === 'dark' ? '#121212' : '#ffffff',
        paper: themeMode === 'dark' ? '#1e1e1e' : '#f5f5f5',
      },
    },
  });

  const handleThemeChange = () => {
    setThemeMode((mode) => (mode === 'dark' ? 'light' : 'dark'));
  };

  const handleFileUpload = async (file) => {
    try {
      if (file.name.endsWith('.json')) {
        const text = await file.text();
        const transcriptData = JSON.parse(text);
        setTranscripts((prev) => new Map(prev).set(file.name, transcriptData));
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setNotification({ message: `Error uploading file: ${error.message}`, severity: 'error' });
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AuthProvider>
              <Routes>
                {/* Public Route */}
                <Route path="/signup" element={<SignUpPage />} />

                {/* Protected Routes */}
                <Route
                  path="/*"
                  element={
                    <ProtectedApp
                      themeMode={themeMode}
                      onThemeChange={handleThemeChange}
                      currentView={currentView}
                      onViewChange={setCurrentView}
                      mediaFiles={[]}
                      selectedBinClip={selectedBinClip}
                      onFileSelect={setSelectedBinClip}
                      onFileUpload={handleFileUpload}
                      chatMessages={chatMessages}
                      onSendMessage={(msg) => setChatMessages((prev) => [...prev, msg])}
                      transcripts={transcripts}
                    />
                  }
                />

                {/* Additional routes for pages that might be separate (optional) */}
                <Route path="/search" element={<SearchPage />} />
                <Route path="/notifications" element={<NotificationsPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/settings" element={<SettingsPage themeMode={themeMode} onThemeChange={handleThemeChange} />} />
                <Route path="/billing" element={<BillingPage />} />
                <Route path="/support" element={<SupportPage />} />
              </Routes>
          </AuthProvider>

          <Snackbar open={!!notification} autoHideDuration={6000} onClose={() => setNotification(null)}>
            {notification && (
              <Alert onClose={() => setNotification(null)} severity={notification.severity}>
                {notification.message}
              </Alert>
            )}
          </Snackbar>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
