import { useState, useEffect } from 'react';

export const GROUP_BREAK_THRESHOLD = 5;
export const PAUSE_INDICATOR_THRESHOLD = 1;

const useProcessTranscripts = (transcripts) => {
  const [displayContent, setDisplayContent] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setIsProcessing(true);

    // If no transcripts selected, bail early
    if (!transcripts.length) {
      setDisplayContent([]);
      setIsProcessing(false);
      return;
    }

    const processTranscripts = () => {
      let globalWordIndex = 0;

      // 1) Log the raw transcripts array
      console.log('[useProcessTranscripts] incoming transcripts:', transcripts);

      return transcripts.map(transcript => {
        // The raw "content" is either .processed_data or the top-level .content
        const content = transcript.content;
        const fileContent = content.processed_data || content;

        // 2) Log the content right before checking if transcript.segments exist
        console.log(`[useProcessTranscripts] File ID=${transcript.id} - raw fileContent:`, fileContent);

        if (!fileContent.transcript || !fileContent.transcript.segments) {
          console.warn("[useProcessTranscripts] Transcript missing segments for:", transcript);
          return null;
        }

        const fileSegments = [];
        const segments = fileContent.transcript.segments;

        // 3) Log segments before building fileSegments
        console.log(`[useProcessTranscripts] File ID=${transcript.id} - original segments:`, segments);

        // Build fileSegments
        segments.forEach((segment, segmentIndex) => {
          // Check for pause
          if (segmentIndex > 0) {
            const prevSegment = segments[segmentIndex - 1];
            const timeGap = segment.start_time - prevSegment.end_time;
            
            if (timeGap >= PAUSE_INDICATOR_THRESHOLD) {
              fileSegments.push({
                startTime: prevSegment.end_time,
                endTime: segment.start_time,
                speaker: prevSegment.speaker,
                fileId: transcript.id,
                fileName: transcript.name,
                globalIndex: `${transcript.id}-pause-${segmentIndex}`,
                words: [{
                  word: '(...)',
                  id: `${transcript.id}-pause-${segmentIndex}`,
                  globalIndex: globalWordIndex++,
                  startTime: prevSegment.end_time,
                  endTime: segment.start_time,
                  position: {
                    segment: segmentIndex,
                    word: -1
                  }
                }],
                metadata: {
                  isPause: true,
                  transcript: {
                    fileId: transcript.id,
                    fileName: transcript.name
                  }
                }
              });
            }
          }

          // Process each word
          const processedWords = segment.words.map((word, wordIndex) => ({
            ...word,
            id: `${transcript.id}-${segmentIndex}-${wordIndex}`,
            globalIndex: globalWordIndex++,
            position: {
              segment: segmentIndex,
              word: wordIndex
            }
          }));

          fileSegments.push({
            ...segment,
            startTime: segment.start_time || segment.start,
            endTime: segment.end_time || segment.end,
            speaker: segment.speaker || 'Unknown',
            fileId: transcript.id,
            fileName: transcript.name,
            globalIndex: `${transcript.id}-${segmentIndex}`,
            words: processedWords,
            metadata: {
              ...segment.metadata,
              transcript: {
                fileId: transcript.id,
                fileName: transcript.name
              }
            }
          });
        });

        // 4) Log what fileSegments look like
        console.log(`[useProcessTranscripts] File ID=${transcript.id} - built fileSegments:`, fileSegments);

        // Group segments by speaker + major time gaps
        const groupedSegments = [];
        let currentGroup = [];

        fileSegments.forEach((segment) => {
          if (currentGroup.length === 0) {
            currentGroup.push(segment);
          } else {
            const lastSegment = currentGroup[currentGroup.length - 1];
            const isSameSpeaker = lastSegment.speaker === segment.speaker;
            const isPause = segment.metadata && segment.metadata.isPause;
            const timeGap = segment.startTime - lastSegment.endTime;

            // Decide if we should start a new group
            if (!isSameSpeaker || (timeGap > GROUP_BREAK_THRESHOLD && !isPause)) {
              groupedSegments.push(currentGroup);
              currentGroup = [segment];
            } else {
              currentGroup.push(segment);
            }
          }
        });

        if (currentGroup.length > 0) {
          groupedSegments.push(currentGroup);
        }

        // 5) Finally, log the grouped segments structure
        console.log(`[useProcessTranscripts] File ID=${transcript.id} - groupedSegments:`, groupedSegments);

        return {
          fileId: transcript.id,
          fileName: transcript.name,
          groupedSegments
        };
      }).filter(Boolean);
    };

    const processed = processTranscripts();

    // 6) Log final processed result
    console.log('[useProcessTranscripts] Final processed transcripts:', processed);

    setDisplayContent(processed);
    setIsProcessing(false);
  }, [transcripts]);

  return [displayContent, isProcessing];
};

export default useProcessTranscripts;
