// src/types/FocusTypes.js

export const FocusTypes = {
    BIN: 'bin',
    TRANSCRIPT: 'transcript',
    PAPERCUT: 'papercut',
    // Future areas:
    // TIMELINE: 'timeline',
    // TRANSCRIPT_VIDEO_PLAYER: 'transcript-video-player',
    // PAPERCUT_VIDEO_PLAYER: 'papercut-video-player',
  };
  
  // Optional: you could define common subSections as constants if you want a controlled vocabulary
  export const FocusSubSections = {
    TABS: 'tabs',
    TOOLBAR: 'toolbar',
    TEXT_AREA: 'text-area',
    METADATA_PANEL: 'metadata-panel',
    CONTENT_EDITOR: 'content-editor',
    SEARCH: 'search',
    FILE_TREE: 'file-tree',
    // Add more as needed...
  };
  