import { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';

export function usePapercutSyncHook() {
  const { token } = useAuth();

  const [papercutMap, setPapercutMap] = useState({});
  const [activeTab, setActiveTab] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  
  // This holds the local changes we plan to save periodically
  const [pendingSaves, setPendingSaves] = useState({});

  // Build auth headers
  const headers = useMemo(() => {
    const h = { 'Content-Type': 'application/json' };
    if (token) {
      h['Authorization'] = `Bearer ${token}`;
    }
    return h;
  }, [token]);

  // -----------------------------
  //   Load/save activeTab to/from localStorage
  // -----------------------------
  useEffect(() => {
    const storedActiveTab = localStorage.getItem('activePapercut');
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    }
  }, []);

  useEffect(() => {
    if (activeTab) {
      localStorage.setItem('activePapercut', activeTab);
    } else {
      localStorage.removeItem('activePapercut');
    }
  }, [activeTab]);

  // -----------------------------
  //   Fetch list of papercuts
  // -----------------------------
  useEffect(() => {
    if (!token) return;

    const fetchPapercutsList = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/papercuts`, { headers });
        if (!res.ok) {
          console.error('Failed to fetch papercuts list. Status:', res.status);
          return;
        }
        const listData = await res.json();
        const newPapercutMap = {};

        for (const pc of listData) {
          newPapercutMap[pc.id] = {
            id: pc.id,
            name: pc.name,
            content: Array.isArray(pc.content) ? pc.content : [],
            created: pc.created_at ? new Date(pc.created_at) : new Date(),
            modified: pc.updated_at ? new Date(pc.updated_at) : new Date(),
            loaded: false
          };
        }
        setPapercutMap(newPapercutMap);
      } catch (error) {
        console.error('Error fetching papercuts list:', error);
      }
    };

    fetchPapercutsList();
  }, [token, headers]);

  // -----------------------------
  //   Derived papercuts array
  // -----------------------------
  const papercuts = useMemo(() => {
    return Object.values(papercutMap);
  }, [papercutMap]);

  // -----------------------------
  //   Fetch content for activeTab (if needed)
  // -----------------------------
  useEffect(() => {
    const fetchPapercutContent = async () => {
      if (!activeTab || !token) return;
      const currentPapercut = papercutMap[activeTab];
      if (currentPapercut && currentPapercut.loaded) return;

      // 1. Verify file type
      const fileRes = await fetch(`${process.env.REACT_APP_API_URL}/api/files/${activeTab}`, { headers });
      if (!fileRes.ok) {
        console.error('Failed to fetch file metadata for', activeTab, 'Status:', fileRes.status);
        return;
      }
      const fileData = await fileRes.json();
      if (fileData.file_type !== 'papercut') {
        console.log('Selected file is not a papercut:', fileData.file_type);
        return;
      }

      // 2. Fetch papercut content
      const pcRes = await fetch(`${process.env.REACT_APP_API_URL}/api/papercuts/${activeTab}`, { headers });
      if (!pcRes.ok) {
        console.error('Failed to fetch papercut content for', activeTab, 'Status:', pcRes.status);
        return;
      }
      const pcData = await pcRes.json();
      let contentArray = pcData.content;

      // Possibly parse content
      if (!Array.isArray(contentArray)) {
        if (typeof contentArray === 'string') {
          try {
            const parsed = JSON.parse(contentArray);
            contentArray = Array.isArray(parsed) ? parsed : [];
          } catch (e) {
            console.error('Invalid JSON content in papercut:', e);
            contentArray = [];
          }
        } else {
          contentArray = [];
        }
      }

      setPapercutMap((prev) => ({
        ...prev,
        [activeTab]: {
          ...prev[activeTab],
          ...fileData,
          content: contentArray,
          created: fileData.created_at ? new Date(fileData.created_at) : new Date(),
          modified: fileData.updated_at ? new Date(fileData.updated_at) : new Date(),
          loaded: true
        }
      }));
    };

    fetchPapercutContent();
  }, [activeTab, token, headers, papercutMap]);

  // -----------------------------
  //   Cursor position
  // -----------------------------
  const updateCursorPosition = useCallback((newPosition) => {
    setCursorPosition(newPosition);
  }, []);

  // ----------------------------------
  //  Updating papercut content locally
  // ----------------------------------
  const updatePapercutContentLocal = useCallback((papercutId, newContent) => {
    if (typeof newContent === 'string') {
      try {
        newContent = JSON.parse(newContent);
      } catch (e) {
        console.error('Invalid content format, resetting to empty array:', e);
        newContent = [];
      }
    }

    setPapercutMap((prev) => {
      if (!prev[papercutId]) return prev;
      return {
        ...prev,
        [papercutId]: {
          ...prev[papercutId],
          content: newContent,
          modified: new Date(),
          loaded: true
        }
      };
    });

    // Mark for saving
    setPendingSaves((prev) => ({
      ...prev,
      [papercutId]: {
        content: newContent,
        lastModified: Date.now()
      }
    }));
  }, []);

  const updatePapercutContent = useCallback((papercutId, newContent) => {
    updatePapercutContentLocal(papercutId, newContent);
  }, [updatePapercutContentLocal]);

  const addContentToPapercut = useCallback((papercutId, content) => {
    const papercut = papercutMap[papercutId];
    if (!papercut || !token) return;

    const updatedContent = [...papercut.content, ...content];
    updatePapercutContentLocal(papercutId, updatedContent);
  }, [papercutMap, token, updatePapercutContentLocal]);

  // ----------------------------------
  //  Insert at the "cursorPosition"
  // ----------------------------------
  const insertContentToPapercut = useCallback((papercutId, newContent, position) => {
    const papercut = papercutMap[papercutId];
    if (!papercut || !token) return;

    // Ensure newContent is array
    if (typeof newContent === 'string') {
      try {
        newContent = JSON.parse(newContent);
      } catch (e) {
        console.error('Invalid newContent format, resetting to empty array:', e);
        newContent = [];
      }
    }
    if (!Array.isArray(newContent)) {
      console.error('newContent must be an array; ignoring insert.');
      return;
    }

    // If no position info, default to appending
    if (!position) {
      const updatedContent = [...papercut.content, ...newContent];
      updatePapercutContentLocal(papercutId, updatedContent);
      return;
    }

    // Insert after the segment where cursor is
    const segIndex = papercut.content.findIndex((seg) => seg.id === position.segmentId);
    if (segIndex === -1) {
      // Fallback: append
      const updatedContent = [...papercut.content, ...newContent];
      updatePapercutContentLocal(papercutId, updatedContent);
      return;
    }

    // Splice newContent after segIndex
    const updatedContent = [
      ...papercut.content.slice(0, segIndex + 1),
      ...newContent,
      ...papercut.content.slice(segIndex + 1)
    ];
    updatePapercutContentLocal(papercutId, updatedContent);
  }, [papercutMap, token, updatePapercutContentLocal]);

  // -----------------------------
  //   createNewPapercut
  // -----------------------------
  const createNewPapercut = useCallback(async () => {
    if (!token) {
      console.error('Cannot create a new papercut without a token');
      return null;
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/papercuts`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ name: `Papercut ${Object.keys(papercutMap).length + 1}` })
    });
  
    if (!response.ok) {
      console.error('Failed to create new papercut, status:', response.status);
      return null;
    }
  
    const newFile = await response.json();
    setPapercutMap((prev) => ({
      ...prev,
      [newFile.id]: {
        id: newFile.id,
        name: newFile.name,
        content: [],
        created: new Date(newFile.created_at),
        modified: new Date(newFile.updated_at),
        loaded: true
      }
    }));
    return newFile.id;
  }, [headers, papercutMap, token]);

  // -----------------------------
  //   Periodic saving
  // -----------------------------
  useEffect(() => {
    if (!token) return;

    const interval = setInterval(async () => {
      const saves = { ...pendingSaves };
      if (Object.keys(saves).length > 0) {
        console.log('[usePapercutSyncHook] Pending saves detected:', saves);
      }
      for (const [papercutId, data] of Object.entries(saves)) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/papercuts/${papercutId}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify({ content: data.content })
          });
          if (!response.ok) {
            console.error(`Failed to save papercut ${papercutId}, status:`, response.status);
          } else {
            console.log(`Successfully saved papercut ${papercutId}`);
            // Remove from pendingSaves
            setPendingSaves((prev2) => {
              const { [papercutId]: _, ...rest } = prev2;
              return rest;
            });
          }
        } catch (error) {
          console.error('[usePapercutSyncHook] Error syncing papercut content:', error);
        }
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [pendingSaves, token, headers]);

  // --------------------------------
  //   Finally, return everything
  // --------------------------------
  return {
    papercuts,
    activeTab,
    setActiveTab,
    cursorPosition,
    updateCursorPosition,
    updatePapercutContent,
    addContentToPapercut,
    insertContentToPapercut,
    createNewPapercut
  };
}
