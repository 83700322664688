import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect
} from 'react';

const TranscriptContext = createContext(undefined);

export const TranscriptProvider = ({ children }) => {
  // Existing states
  const [highlightedWord, setHighlightedWord] = useState(null);
  const [displayContent, setDisplayContent] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);

  // Existing: A state to store currently highlighted content
  const [highlightedContent, setHighlightedContent] = useState([]);

  // NEW: Transcript data keyed by fileId
  // shape: { [fileId]: { status: 'IN_PROGRESS' | 'COMPLETED' | 'FAILED', content: any } }
  const [transcripts, setTranscripts] = useState({});

  // We'll store polling intervals here, so we can clear them
  const pollingIntervalsRef = useRef({});

  // Existing callbacks:
  const findInSource = useCallback((word, fileId) => {
    setHighlightedWord({ ...word, fileId });
  }, []);

  const clearHighlight = useCallback(() => {
    setHighlightedWord(null);
  }, []);

  // Existing: A function to update highlighted content array
  const updateHighlightedContent = useCallback((segments) => {
    console.log('🔎 Newly highlighted content:', JSON.parse(JSON.stringify(segments)));
    setHighlightedContent(segments);
  }, []);

  /**
   * MANUAL REFRESH: calls your /api/files/:id/transcript endpoint once
   */
  const refreshTranscript = useCallback(async (fileId) => {
    try {
      const resp = await fetch(`/api/files/${fileId}/transcript`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
          // Add auth header if needed
        }
      });

      const data = await resp.json();
      if (!resp.ok) {
        console.error('[refreshTranscript] error:', data.error || data);
        return; // or show an alert/UI error
      }

      // The endpoint may return "message" keys, e.g. 'Transcription still in progress on AWS'
      if (data.message === 'Transcription still in progress on AWS') {
        setTranscripts(prev => ({
          ...prev,
          [fileId]: {
            status: 'IN_PROGRESS',
            content: prev[fileId]?.content || null // keep old content if any
          }
        }));
      } else if (data.message === 'Transcription job failed on AWS side') {
        setTranscripts(prev => ({
          ...prev,
          [fileId]: {
            status: 'FAILED',
            content: null
          }
        }));
      } else if (data.message === 'Transcription completed & stored') {
        // The endpoint includes: { file: updatedFile }, so transcript is in updatedFile.content
        setTranscripts(prev => ({
          ...prev,
          [fileId]: {
            status: 'COMPLETED',
            content: data.file?.content || null
          }
        }));
      } else {
        console.warn('[refreshTranscript] unexpected response:', data);
      }
    } catch (err) {
      console.error('[refreshTranscript] fetch error:', err);
    }
  }, []);

  /**
   * POLLING: repeatedly call refreshTranscript(fileId) until status != 'IN_PROGRESS'
   * or until manually stopped.
   */
  const startPollingTranscript = useCallback((fileId, intervalMs = 5000) => {
    // Clear any existing interval for this fileId
    if (pollingIntervalsRef.current[fileId]) {
      clearInterval(pollingIntervalsRef.current[fileId]);
    }

    // Immediately do one fetch:
    refreshTranscript(fileId);

    // Then set up the interval
    const intervalId = setInterval(() => {
      // Optionally, if we see it's COMPLETED or FAILED, we can stop polling automatically.
      const currentStatus = transcripts[fileId]?.status;
      if (currentStatus === 'COMPLETED' || currentStatus === 'FAILED') {
        console.log(`[startPollingTranscript] File ${fileId} is no longer IN_PROGRESS, stopping...`);
        clearInterval(intervals => intervals[fileId]);
      } else {
        refreshTranscript(fileId);
      }
    }, intervalMs);

    pollingIntervalsRef.current[fileId] = intervalId;
    console.log(`[startPollingTranscript] Started polling for fileId=${fileId}`);
  }, [refreshTranscript, transcripts]);

  /**
   * STOP POLLING: clear the interval for a specific fileId
   */
  const stopPollingTranscript = useCallback((fileId) => {
    if (pollingIntervalsRef.current[fileId]) {
      clearInterval(pollingIntervalsRef.current[fileId]);
      delete pollingIntervalsRef.current[fileId];
      console.log(`[stopPollingTranscript] Stopped polling for fileId=${fileId}`);
    }
  }, []);


  useEffect(() => {
    // Capture the current intervals in a local variable
    const intervals = pollingIntervalsRef.current;
  
    // Cleanup function
    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, []);
  

  // Return all context values
  const value = {
    // Existing:
    highlightedWord,
    findInSource,
    clearHighlight,
    displayContent,
    setDisplayContent,
    selectedSegments,
    setSelectedSegments,
    highlightedContent,
    updateHighlightedContent,

    // NEW:
    transcripts,                // { fileId: { status, content } }
    refreshTranscript,          // manual refresh
    startPollingTranscript,     // begin auto-polling
    stopPollingTranscript       // stop auto-polling
  };

  return (
    <TranscriptContext.Provider value={value}>
      {children}
    </TranscriptContext.Provider>
  );
};

export const useTranscript = () => {
  const context = useContext(TranscriptContext);
  if (!context) {
    throw new Error('useTranscript must be used within TranscriptProvider');
  }
  return context;
};
