import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  Add as AddIcon,
  Upload as UploadIcon,
  VideoFile as PaperCutIcon
} from '@mui/icons-material';

import { useFileSystem } from '../../contexts/FileSystemContext';
import { usePapercuts } from '../../contexts/PapercutContext';
import { useFocus } from '../../contexts/FocusContext';
import FileItem from './FileItem';
import { useAuth } from '../../contexts/AuthContext';

// 1) Import Socket.IO client
import { io } from 'socket.io-client';

const MediaSidebar = () => {
  const { createNewPapercut } = usePapercuts();
  const { 
    addFilesMulti,  // <--- our new multi-upload function
    setSelectedItems,
    hasContent,
    files,
    fetchFiles
  } = useFileSystem();
  const { focusBin } = useFocus();
  const { token } = useAuth();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [transcriptCompleteMsg, setTranscriptCompleteMsg] = useState(null);
  const [isDraggingExternal, setIsDraggingExternal] = useState(false);
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null);

  const dragCounter = useRef(0);
  const fileInputRef = useRef(null);

  // 2) Store our socket instance
  const socketRef = useRef(null);

  // Hardcoded user ID (if you’re still using it)
  const userId = 999;

  // --------------------------------------------------------
  // Socket.IO: Connect once on mount
  // --------------------------------------------------------
  useEffect(() => {
    if (!token) {
      console.log('[MediaSidebar] No token, skipping socket connect.');
      return;
    }

    const socketURL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    console.log('[MediaSidebar] Connecting to', socketURL, 'with token:', token);

    const socket = io(socketURL, {
      transports: ['websocket'],
      auth: {
        token: `Bearer ${token}`
      }
    });

    socketRef.current = socket;

    socket.on('connect', () => {
      console.log('[MediaSidebar] Socket connected:', socket.id);
    });

    socket.on('connect_error', (err) => {
      console.error('[MediaSidebar] Socket connect error:', err.message);
    });

    socket.on('transcription-update', (data) => {
      console.log('[MediaSidebar] Received transcription-update:', data);
      // If you want to display a success message when status=COMPLETED,
      // you can do something like:
      if (data.status === 'COMPLETED') {
        setTranscriptCompleteMsg(`Transcription complete for fileId=${data.fileId}`);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [token]);

  // --------------------------------------------------------
  // Handlers
  // --------------------------------------------------------
  const handleAddClick = (event) => {
    setAddMenuAnchorEl(event.currentTarget);
  };

  const handleAddMenuClose = () => {
    setAddMenuAnchorEl(null);
  };

  const handleCreateNewPapercut = async () => {
    await createNewPapercut();
    await fetchFiles();
    handleAddMenuClose();
  };

  /**
   * Rewritten: Upload multiple files in a single request to /api/files/upload-multi.
   */
  const handleFileUpload = useCallback(async (filesToUpload) => {
    handleAddMenuClose();
    setIsUploading(true);
    setUploadError(null);

    try {
      // Convert FileList to a real array
      const fileArray = Array.from(filesToUpload);

      // 1) Call multi-upload function
      const newFileRecords = await addFilesMulti(fileArray, null);
      console.log('[handleFileUpload] multi-upload returned:', newFileRecords);

      // 2) For each newly created file, tell our Socket server to watch
      if (socketRef.current) {
        newFileRecords.forEach((fileRecord) => {
          socketRef.current.emit('watch-transcription', {
            fileId: fileRecord.id,
            userId // remove if not needed
          });
        });
      }

    } catch (error) {
      console.error('[handleFileUpload] Multi-upload failed:', error);
      setUploadError(error.message);
    } finally {
      setIsUploading(false);
    }
  }, [addFilesMulti, userId]);

  // Drag & drop logic
  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.types.includes('Files')) {
      setIsDraggingExternal(true);
    }
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDraggingExternal(false);
    }
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingExternal(false);
    dragCounter.current = 0;

    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length > 0) {
      handleFileUpload(droppedFiles);
    }
  }, [handleFileUpload]);

  const handleContainerClick = (event) => {
    if (event.target === event.currentTarget) {
      setSelectedItems([]);
      focusBin();
    }
  };

  // Attach global drag events
  useEffect(() => {
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);
    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

  // Separate files into transcripts and papercuts
  const transcripts = Object.values(files).filter((f) => f.type === 'file');
  const papercuts = Object.values(files).filter((f) => f.type === 'papercut');

  return (
    <Paper
      sx={{
        width: 250,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.default',
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      {/* If we have a success message, show an Alert at the top */}
      {transcriptCompleteMsg && (
        <Box sx={{ position: 'absolute', top: 16, left: 16, right: 16, zIndex: 1500 }}>
          <Alert 
            severity="success" 
            onClose={() => setTranscriptCompleteMsg(null)}
          >
            {transcriptCompleteMsg}
          </Alert>
        </Box>
      )}

      {/* Header */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: 'background.paper',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 48,
          px: 2
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: 'text.primary',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
            fontWeight: 500
          }}
        >
          Assets
        </Typography>
        <IconButton
          size="small"
          onClick={handleAddClick}
          sx={{
            bgcolor: 'action.hover',
            width: 24,
            height: 24,
            '&:hover': {
              bgcolor: 'action.selected'
            }
          }}
        >
          <AddIcon fontSize="small" />
        </IconButton>
        <Menu
          anchorEl={addMenuAnchorEl}
          open={Boolean(addMenuAnchorEl)}
          onClose={handleAddMenuClose}
          PaperProps={{
            sx: {
              bgcolor: 'background.paper',
              borderRadius: 1,
              boxShadow: 2
            }
          }}
        >
          <MenuItem
            onClick={() => fileInputRef.current?.click()}
            sx={{ fontSize: '0.875rem' }}
          >
            <UploadIcon fontSize="small" sx={{ mr: 1 }} />
            Upload
          </MenuItem>
          <MenuItem
            onClick={handleCreateNewPapercut}
            sx={{ fontSize: '0.875rem' }}
          >
            <PaperCutIcon fontSize="small" sx={{ mr: 1 }} />
            New Papercut
          </MenuItem>
        </Menu>
        {/* Hidden file input */}
        <input
          ref={fileInputRef}
          type="file"
          hidden
          multiple
          onChange={(e) => handleFileUpload(Array.from(e.target.files))}
          accept=".json,.docx,.srt,.srtx,video/*,audio/*"
        />
      </Box>

      {/* File listing */}
      <Box
        onClick={handleContainerClick}
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          p: 1
        }}
      >
        {!hasContent() ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontStyle: 'italic',
                textAlign: 'center',
                color: 'text.disabled'
              }}
            >
              Upload something to start
            </Typography>
          </Box>
        ) : (
          <>
            <Typography
              variant="overline"
              sx={{ color: 'text.primary', mb: 1 }}
            >
              Transcripts
            </Typography>
            {transcripts.map((file) => (
              <FileItem key={file.id} file={file} />
            ))}

            <Typography
              variant="overline"
              sx={{ color: 'text.primary', mt: 2, mb: 1 }}
            >
              Papercuts
            </Typography>
            {papercuts.map((file) => (
              <FileItem key={file.id} file={file} />
            ))}
          </>
        )}
      </Box>

      {/* Drag overlay */}
      {isDraggingExternal && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          }}
        >
          <Typography variant="body1" sx={{ color: 'common.white' }}>
            Drop files here to upload
          </Typography>
        </Box>
      )}

      {/* Upload progress overlay */}
      {isUploading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1100
          }}
        >
          <CircularProgress color="primary" />
          <Typography
            variant="body2"
            sx={{
              color: 'common.white',
              mt: 2
            }}
          >
            Uploading files...
          </Typography>
        </Box>
      )}

      {/* Error alert */}
      {uploadError && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 16,
            right: 16,
            zIndex: 1000
          }}
        >
          <Alert severity="error" onClose={() => setUploadError(null)}>
            {uploadError}
          </Alert>
        </Box>
      )}
    </Paper>
  );
};

export default MediaSidebar;
