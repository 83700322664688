import React, { memo } from 'react';
import { Typography } from '@mui/material';

export const SegmentWord = memo(({ 
  word, 
  segment,
  onWordHover,
  highlightStyle,
  nextWord,
  isFirstInSegment,
  isSelected,
  selection
}) => {
  // Check if this word is within the current selection
  const isInSelection = selection && selection.some(sel => {
    return (
      sel.segmentId === segment.id && 
      sel.words.some(w => w.id === word.id)
    );
  });

  return (
    <Typography
      component="span"
      variant="body2"
      className="word"
      data-type="word"
      data-segment-id={segment.id}
      data-word-id={word.id}
      data-start-time={word.startTime}
      data-end-time={word.endTime}
      data-index={word.index}
      contentEditable="true"
      suppressContentEditableWarning={true}
      onMouseEnter={() => onWordHover?.({ ...word, segmentId: segment.id, wordId: word.id })}
      onMouseLeave={() => onWordHover?.(null)}
      sx={{
        display: 'inline',
        cursor: 'text',
        userSelect: 'text',
        px: '1px',
        py: 0.25,
        borderRadius: 1,
        '&:hover': {
          bgcolor: 'action.hover'
        },
        ...(isInSelection && {
          bgcolor: 'primary.light',
          color: 'primary.contrastText'
        }),
        '&::selection': {
          bgcolor: 'primary.light',
          color: 'primary.contrastText'
        },
        ...highlightStyle
      }}
    >
      {word.text}
    </Typography>
  );
});

SegmentWord.displayName = 'SegmentWord';

export default SegmentWord;
