import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import LoginPage from '../Pages/LoginPage';
import { FileSystemProvider } from '../../contexts/FileSystemContext';
import { SpeakerColorProvider } from '../../contexts/SpeakerColorContext';
import { PapercutProvider } from '../../contexts/PapercutContext';
import { PapercutHistoryProvider } from '../../contexts/PapercutHistoryContext';
import { TranscriptStylingProvider } from '../../contexts/TranscriptStylingContext';
import { ClipboardProvider } from '../../contexts/ClipboardContext';
import { PapercutClipboardProvider } from '../../contexts/PapercutClipboardContext';
import { TranscriptProvider } from '../../contexts/TranscriptContext'; 
import { FocusProvider } from '../../contexts/FocusContext';
import MainLayout from './MainLayout';

const ProtectedApp = (props) => {
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    const verifyToken = async () => {
      const storedToken = localStorage.getItem('token');

      if (storedToken && !isAuthenticated) {
        try {
          const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/verify`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${storedToken}`
            },
          });

          if (response.ok) {
            const data = await response.json();
            if (data.user) {
              login(data.user);
            } else {
              localStorage.removeItem('token');
              localStorage.removeItem('user');
            }
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
      }
    };

    verifyToken();
  }, [isAuthenticated, login]);

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  return (
    <FocusProvider>
      <FileSystemProvider onFileUpload={props.onFileUpload}>
      <PapercutProvider>
        <SpeakerColorProvider>
          <PapercutHistoryProvider>
            <TranscriptStylingProvider>
              <ClipboardProvider>
                <PapercutClipboardProvider>
                  <TranscriptProvider>
                    <MainLayout {...props}>
                      {props.children}
                    </MainLayout>
                  </TranscriptProvider>
                </PapercutClipboardProvider>
              </ClipboardProvider>
            </TranscriptStylingProvider>
          </PapercutHistoryProvider>
        </SpeakerColorProvider>
        </PapercutProvider>
      </FileSystemProvider>
  </FocusProvider>
  );
  
};

export default ProtectedApp;
