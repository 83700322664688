import { useContext, useCallback } from 'react';
import { PapercutClipboardContext } from '../../contexts/PapercutClipboardContext';

export const usePapercutClipboard = (source) => {
  const { storeInPapercutClipboard, getFromPapercutClipboard } = useContext(PapercutClipboardContext);

  const copyPapercutContent = useCallback((content) => {
    if (!content || content.length === 0) return false;
    storeInPapercutClipboard(content, source);
    return true;
  }, [storeInPapercutClipboard, source]);

  const pastePapercutContent = useCallback((onPaste) => {
    const { content } = getFromPapercutClipboard();
    if (!content) return false;
    onPaste({ content, source });
    return true;
  }, [getFromPapercutClipboard, source]);

  return { copyPapercutContent, pastePapercutContent };
};
