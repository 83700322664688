import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

export const ScrollContainer = ({ children, onScroll }) => {
  const scrollRef = useRef(null);
  const lastScrollPosition = useRef(0);

  // Only update scroll position when content height changes
  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const observer = new ResizeObserver(() => {
      if (lastScrollPosition.current) {
        scrollElement.scrollTop = lastScrollPosition.current;
      }
    });

    observer.observe(scrollElement);
    return () => observer.disconnect();
  }, []);

  const handleScroll = (e) => {
    lastScrollPosition.current = e.target.scrollTop;
    if (onScroll) {
      onScroll(e);
    }
  };

  return (
    <Box
      ref={scrollRef}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme => theme.palette.divider,
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: theme => theme.palette.action.hover,
        }
      }}
      onScroll={handleScroll}
    >
      <Box sx={{ p: 2, pb: '100px' }}>
        {children}
      </Box>
    </Box>
  );
};