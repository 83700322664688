export const promptTemplates = [
  {
    name: "Edit Transcript",
    template: `
Please edit the transcript according to the instructions below:
{user_input}

Focus on maintaining timing, speaker attribution, and remove unnecessary content as instructed.
    `
  }
];
