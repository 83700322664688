import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const FileSystemContext = createContext(undefined);

export const FileType = {
  FOLDER: 'folder',
  VIDEO: 'video',
  DOCX: 'docx',
  SRT: 'srt',
  JSON: 'json',
  PAPERCUT: 'papercut',
  UNKNOWN: 'unknown'
};

function determineFileType(filename) {
  const extension = filename.split('.').pop().toLowerCase();
  switch (extension) {
    case 'docx':
      return FileType.DOCX;
    case 'srt':
    case 'srtx':
      return FileType.SRT;
    case 'json':
      return FileType.JSON;
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'mkv':
      return FileType.VIDEO;
    case 'papercut':
      return FileType.PAPERCUT;
    default:
      return FileType.UNKNOWN;
  }
}

export const FileSystemProvider = ({ children, onError }) => {
  const [items, setItems] = useState([]); 
  const [selectedItems, setSelectedItems] = useState([]);
  const [activePapercutId, setActivePapercutId] = useState(null);
  const [errorState, setErrorState] = useState(null);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);
  const [currentParentId, setCurrentParentId] = useState(null); 
  const [expandedFolders, setExpandedFolders] = useState(new Set());

  const { token } = useAuth();

  const filesMap = useMemo(() => {
    return items.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [items]);

  const getHeaders = useCallback(() => {
    if (!token) return null;
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
  }, [token]);

  const apiCall = useCallback(async (endpoint, options = {}) => {
    const headers = getHeaders();
    if (!headers) throw new Error('No authentication token available');

    console.log('API Call to:', endpoint, {
      options,
      headers,
      // Body might be a string, so let's carefully parse it if it's JSON:
      body: options.body ? JSON.parse(options.body) : undefined
    });

    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      ...options,
      headers: {
        ...headers,
        ...options.headers
      }
    });

    if (!response.ok) {
      let errorDetail = '';
      try {
        const errorJson = await response.json();
        errorDetail = JSON.stringify(errorJson);
      } catch (e) {
        errorDetail = await response.text();
      }
      throw new Error(`API call to ${endpoint} failed: ${response.status} - ${errorDetail}`);
    }

    return response.json();
  }, [getHeaders]);

  const getDirectoryItems = useCallback((parentId) => {
    console.log(`Getting items for parentId: ${parentId}`);
    const filteredItems = items
      .filter(item => item.parentId === (parentId === null ? null : parentId.toString()))
      .sort((a, b) => a.order - b.order);

    console.log('Filtered items for parentId', parentId, ':', filteredItems);
    return filteredItems;
  }, [items]);

  const hasContent = useCallback(() => {
    const directoryItems = getDirectoryItems(currentParentId);
    console.log('Directory items for', currentParentId, ':', directoryItems);
    return directoryItems && directoryItems.length > 0;
  }, [getDirectoryItems, currentParentId]);

  const fetchItems = useCallback(async (parentId = currentParentId) => {
    if (!token) return;
    try {
      const queryParam = parentId ? `?parentId=${parentId}` : '';
      const data = await apiCall(`/api/directory${queryParam}`);
      const normalized = data.map(item => ({
        id: item.id.toString(),
        name: item.name,
        transcription_job_id: item.transcription_job_id,
        video_key: item.video_key,
        audio_key: item.audio_key,
        parentId: item.folder_id === null ? null : item.folder_id.toString(),
        order: item.order_index,
        type: item.item_type === 'folder' 
          ? 'folder' 
          : (item.file_type || determineFileType(item.name)),
        content: item.content ? JSON.stringify(item.content) : null,
        item_type: item.item_type
      })).sort((a, b) => a.order - b.order);

      setItems(normalized);
    } catch (error) {
      console.error('Error fetching items:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [token, onError, currentParentId, apiCall]);

  const fetchFolderItems = useCallback(async (parentId) => {
    try {
      console.log('Fetching items for parent:', parentId);
      const queryParam = parentId ? `?parentId=${parentId}` : '';
      const data = await apiCall(`/api/directory${queryParam}`);

      console.log('Received data:', data);

      const normalized = data.map(item => ({
        id: item.id.toString(),
        name: item.name,
        parentId: item.folder_id === null ? null : item.folder_id.toString(),
        order: item.order_index,
        type: item.item_type === 'folder' 
          ? 'folder' 
          : (item.file_type || determineFileType(item.name)),
        content: item.content ? JSON.stringify(item.content) : null,
        item_type: item.item_type
      })).sort((a, b) => a.order - b.order);

      console.log('Normalized items:', normalized);

      setItems(prev => {
        console.log('Previous items:', prev);
        // Remove items with the same parentId and add new ones
        const filtered = prev.filter(it => it.parentId !== (parentId?.toString() ?? null));
        const newItems = [...filtered, ...normalized];
        console.log('New items state:', newItems);
        return newItems;
      });
    } catch (error) {
      console.error('Error fetching folder items:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [apiCall, onError]);

  /**
   * AddFile: improved logging
   */
  const addFile = useCallback(async (file, parentId) => {
    if (!token) {
      console.log("addFile called, but no token is present. Aborting.");
      return;
    }

    // ** New logs to see what's being passed **
    console.log("addFile invoked with:", { fileName: file?.name, parentId });

    const formData = new FormData();
    formData.append('file', file, file.name);

    if (parentId) {
      // Log that we're appending parentId
      console.log("Appending parentId to formData:", parentId);
      formData.append('parentId', parentId);
    } else {
      // If parentId is falsy (null/undefined/0/''), log that we're skipping
      console.log("No parentId provided, skipping parentId in formData");
    }

    try {
      const headers = { 'Authorization': `Bearer ${token}` };

      // ** Another log to show final fetch parameters (excluding the raw file data) **
      console.log("About to POST /api/files/upload with headers:", headers);
      // Logging formData directly won't show the file or parentId easily, but let's do a small trick:
      for (let [key, value] of formData.entries()) {
        console.log(`FormData field: ${key} =>`, value);
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/files/upload`, {
        method: 'POST',
        headers,
        body: formData
      });

      console.log("Upload request completed with status:", response.status);

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.status}`);
      }

      const result = await response.json();
      console.log("Upload success, server responded with:", result);

      // Refresh items in that folder
      await fetchFolderItems(parentId || null);

      return result.file.id.toString();
    } catch (error) {
      console.error('Upload error:', error);
      setErrorState(`Upload failed: ${error.message}`);
      if (onError) onError(error.message);
    }
  }, [token, fetchFolderItems, onError]);

  // In FileSystemContext.js (or wherever you define "addFile")
const addFilesMulti = useCallback(
  async (filesArray, parentId) => {
    if (!token) {
      console.warn("addFilesMulti called but no token is present. Aborting.");
      return [];
    }

    console.log("[addFilesMulti] Uploading multiple files:", filesArray);

    const formData = new FormData();
    for (const file of filesArray) {
      formData.append('files', file, file.name);
    }

    // If your server expects parentId in the form, uncomment:
    /*
    if (parentId) {
      formData.append('parentId', parentId);
    }
    */

    try {
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/uploadMulti/upload-multi`, {
        method: 'POST',
        headers,
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Multi-upload failed: ${response.status}`);
      }

      const result = await response.json();
      // e.g. { message: 'Files uploaded...', files: [...] }
      const { files: updatedFileRecords } = result;

      console.log("[addFilesMulti] Upload success. Updated file records:", updatedFileRecords);

      // Re-fetch items so the newly uploaded files appear in the UI
      await fetchFolderItems(parentId || null);

      return updatedFileRecords;
    } catch (error) {
      console.error('[addFilesMulti] Error uploading multiple files:', error);
      setErrorState(`Multi-upload failed: ${error.message}`);
      if (onError) onError(error.message);
      return [];
    }
  },
  [token, fetchFolderItems, setErrorState, onError]
);


  const createFolder = useCallback(async (parentId) => {
    try {
      await apiCall('/api/folders', {
        method: 'POST',
        body: JSON.stringify({ name: 'New Folder', parentId })
      });
      await fetchFolderItems(parentId || null);
    } catch (error) {
      console.error('Create folder error:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [apiCall, fetchFolderItems, onError]);

  const moveItem = useCallback(async (itemId, newParentId, newOrder = null) => {
    const item = filesMap[itemId];
    if (!item) return;

    const payload = {};

    if (typeof newParentId !== 'undefined') {
      payload.parentId = newParentId === null ? null : String(newParentId);
    }

    if (newOrder !== null) {
      payload.order = newOrder;
    }

    console.log('moveItem payload:', payload);

    try {
      const endpoint = (item.type === FileType.FOLDER)
        ? `/api/folders/${itemId}`
        : `/api/files/${itemId}`;

      await apiCall(endpoint, {
        method: 'PUT',
        body: JSON.stringify(payload)
      });

      const oldParent = item.parentId || null;
      await fetchFolderItems(oldParent);
      if (newParentId !== oldParent) {
        await fetchFolderItems(newParentId || null);
      }
    } catch (error) {
      console.error('Move item error:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [filesMap, apiCall, fetchFolderItems, onError]);

  const deleteItem = useCallback(async (itemId) => {
    const item = filesMap[itemId];
    if (!item) return;

    const isFolder = item.type === FileType.FOLDER;

    try {
      await apiCall(`/api/${isFolder ? 'folders' : 'files'}/${itemId}`, {
        method: 'DELETE'
      });

      const parentId = item.parentId || null;
      await fetchFolderItems(parentId);
      setSelectedItems(prev => prev.filter(id => id !== itemId));
      if (activePapercutId === itemId) {
        setActivePapercutId(null);
      }
    } catch (error) {
      console.error('Delete item error:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [filesMap, apiCall, fetchFolderItems, activePapercutId, onError]);

  const renameItem = useCallback(async (itemId, newName) => {
    const item = filesMap[itemId];
    if (!item) return;

    const isFolder = item.type === FileType.FOLDER;

    try {
      const endpoint = isFolder 
        ? `/api/folders/${itemId}`
        : `/api/files/${itemId}`;

      await apiCall(endpoint, {
        method: 'PUT',
        body: JSON.stringify({ name: newName })
      });

      const parentId = item.parentId || null;
      await fetchFolderItems(parentId);
    } catch (error) {
      console.error('Rename item error:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [filesMap, apiCall, fetchFolderItems, onError]);

  const toggleFolder = useCallback(async (folderId) => {
    console.log('Toggling folder:', folderId);
    console.log('Current expanded folders:', expandedFolders);

    let isExpanding = false;
    setExpandedFolders(prev => {
      const next = new Set(prev);
      if (next.has(folderId)) {
        console.log('Collapsing folder:', folderId);
        next.delete(folderId);
      } else {
        console.log('Expanding folder:', folderId);
        isExpanding = true;
        next.add(folderId);
      }
      return next;
    });

    if (isExpanding) {
      await fetchFolderItems(folderId);
    }
  }, [expandedFolders, fetchFolderItems]);

  const handleSelection = useCallback((itemId, event) => {
    console.log("handleSelection triggered:", itemId);
    const file = filesMap[itemId];
    console.log("handleSelection called with:", itemId, "file:", file);
  
    if (!file) return;
  
    // If it's a "papercut"
    if (file.type === 'papercut') {
      setActivePapercutId(itemId);
      return;
    }
  
    // If it's a regular file (not a folder or papercut),
    // handle multi-select logic (ctrl/cmd/shift):
    if (file.type !== 'folder' && file.type !== 'papercut') {
      if (event?.metaKey || event?.ctrlKey) {
        setSelectedItems(prev => {
          const newSet = new Set(prev);
          if (newSet.has(itemId)) {
            newSet.delete(itemId);
          } else {
            newSet.add(itemId);
          }
          return Array.from(newSet);
        });
      } else if (event?.shiftKey && lastSelectedItem) {
        const parentId = file.parentId || null;
        const dirItems = getDirectoryItems(parentId)
          .filter(f => f.type !== 'papercut' && f.type !== 'folder');
  
        const currentIndex = dirItems.findIndex(i => i.id === itemId);
        const lastIndex = dirItems.findIndex(i => i.id === lastSelectedItem);
  
        if (currentIndex !== -1 && lastIndex !== -1) {
          const start = Math.min(currentIndex, lastIndex);
          const end = Math.max(currentIndex, lastIndex);
          const newSelection = dirItems.slice(start, end + 1).map(i => i.id);
          setSelectedItems(newSelection);
        }
      } else {
        setSelectedItems([itemId]);
      }
  
      setLastSelectedItem(itemId);
  
      // ─────────────────────────────────────────────────────────────────────────
      // HERE: Log or fetch the transcript status for this file, with auth header
      // ─────────────────────────────────────────────────────────────────────────
      if (file.transcription_job_id) {
        console.log("[Transcript] Checking transcript for job id:", file.transcription_job_id);
      } else {
        console.log("[Transcript] No transcription_job_id found, skipping fetch...");
      }
  
      console.log(`[Transcript] calling GET /api/files/${itemId}/transcript`);
  
      // Example: get token from your auth context or some hook, e.g.:
      // const { token } = useAuth();  // Make sure you have 'token' accessible in scope
  
     // Just do a quick GET to see if the transcript is ready, or skip entirely:
     fetch(`${process.env.REACT_APP_API_URL}/api/files/${itemId}/transcript`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
      .then(resp => {
        if (!resp.ok) throw new Error(`HTTP error ${resp.status}`);
        return resp.json();
      })
      .then(json => {
        console.log("[Transcript] Response from server:", json);
    
        if (json.file && 
            (json.message === 'Transcription completed & stored' ||
             json.message === 'Transcript already converted & stored')) {
          // Option A: Re-fetch entire directory, e.g. root if this is top-level
          fetchItems(currentParentId); // or fetchFolderItems(...) if inside a folder
    
          // or Option B: fetch the single file:
          // apiCall(`/api/files/${json.file.id}`).then(updatedFile => {
          //   updateItemInState(updatedFile);
          // });
    
        }
      })
      .catch(err => console.error("[Transcript] Error calling /api/files/:id/transcript:", err));
    
      // ─────────────────────────────────────────────────────────────────────────
    }
  }, [
    filesMap, 
    getDirectoryItems, 
    lastSelectedItem, 
    setActivePapercutId, 
    setSelectedItems, 
    setLastSelectedItem,
    token,
    fetchItems,
    currentParentId
  ]);
  
  

  const getTranscriptData = useCallback((fileIds) => {
    return fileIds
      .map(id => {
        const f = filesMap[id];
        if (!f || f.item_type !== 'file' || !f.content) return null;

        const parsed = JSON.parse(f.content);
        return {
          id: f.id,
          name: f.name,
          content: parsed
        };
      })
      .filter(Boolean);
  }, [filesMap]);

  useEffect(() => {
    if (activePapercutId) {
      localStorage.setItem('activePapercutId', activePapercutId);
    } else {
      localStorage.removeItem('activePapercutId');
    }
  }, [activePapercutId]);

  useEffect(() => {
    const savedPapercutId = localStorage.getItem('activePapercutId');
    if (savedPapercutId && filesMap[savedPapercutId]) {
      setActivePapercutId(savedPapercutId);
    }
  }, [filesMap]);

  useEffect(() => {
    if (token) {
      fetchItems();
    }
  }, [token, fetchItems]);

  const value = useMemo(() => ({
    files: filesMap,
    selectedItems,
    setSelectedItems,
    activePapercutId,
    setActivePapercutId,
    lastSelectedItem,
    addFile,
    addFilesMulti,
    createFolder,
    moveItem,
    deleteItem,
    getDirectoryItems,
    setLastSelectedItem,
    handleSelection,
    getTranscriptData,
    renameItem,
    hasContent,
    currentParentId,
    setCurrentParentId,
    fetchFiles: fetchItems,
    expandedFolders,
    toggleFolder
  }), [
    filesMap,
    selectedItems,
    setSelectedItems,
    activePapercutId,
    lastSelectedItem,
    addFile,
    addFilesMulti,
    createFolder,
    moveItem,
    deleteItem,
    getDirectoryItems,
    getTranscriptData,
    renameItem,
    hasContent,
    handleSelection,
    currentParentId,
    fetchItems,
    expandedFolders,
    toggleFolder
  ]);

  return (
    <FileSystemContext.Provider value={value}>
      {children}
      {errorState && (
        <Snackbar open={!!errorState} autoHideDuration={6000} onClose={() => setErrorState(null)}>
          <Alert onClose={() => setErrorState(null)} severity="error">
            {errorState}
          </Alert>
        </Snackbar>
      )}
    </FileSystemContext.Provider>
  );
};

export const useFileSystem = () => {
  const context = useContext(FileSystemContext);
  if (!context) {
    throw new Error('useFileSystem must be used within FileSystemProvider');
  }
  return context;
};

export default FileSystemContext;
