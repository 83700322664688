import React, { forwardRef, useState, useCallback, memo, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { animated } from '@react-spring/web';
import Paper from '@mui/material/Paper';
import { SegmentWord } from './SegmentWord';
import { keyframes } from '@mui/system';

const flash = keyframes`
  0% {
    background-color: rgba(255, 255, 100, 0.6); 
  }
  50% {
    background-color: rgba(255, 255, 100, 0.9);
  }
  100% {
    background-color: transparent;
  }
`;



const AnimatedPaper = animated(Paper);

const Segment = memo(forwardRef(({
  segment,
  theme,
  getSpeakerColor,
  onDeleteSegment,
  onWordHover,
  style,
  dragHandleProps,
  getHighlightStyle,
  isDragging,
  activeWordId,
  onWordClick,
  isSelected,
  isNewlyInserted = false
}, ref) => {
  const [isHovered, setIsHovered] = useState(false);
  const [flashActive, setFlashActive] = useState(false);

  const handleDeleteClick = useCallback((e) => {
    e.stopPropagation();
    onDeleteSegment?.(segment.id);
  }, [onDeleteSegment, segment.id]);

  const handleWordClick = useCallback((wordId, position) => {
    onWordClick?.(wordId, segment.id, position);
  }, [onWordClick, segment.id]);

  const speakerColor = getSpeakerColor(segment.speaker);

  useEffect(() => {
    if (isNewlyInserted) {
         setFlashActive(true);
         const timer = setTimeout(() => {
           setFlashActive(false);
         }, 3000); // after 3 seconds, reset the flash
         return () => clearTimeout(timer);
       }
     }, [isNewlyInserted]);

  return (
    <AnimatedPaper
      ref={ref}
      elevation={isDragging ? 4 : 1}
      style={{
        ...style,
        position: 'relative',
        backgroundColor: isDragging 
          ? theme.palette.action.hover
          : theme.palette.background.paper,
        width: '100%',
      }}
      sx={{ 
        transition: theme.transitions.create(['box-shadow', 'background-color'], {
          duration: '0.1s',
          easing: 'ease-in',
        }),
        animation: flashActive ? `${flash} 3s ease-in-out` : 'none',
        animationFillMode: 'forwards',
        '&:hover .drag-handle': {
          opacity: 1
        },
        '&:hover': {
          backgroundColor: isDragging 
            ? theme.palette.action.hover
            : theme.palette.action.hover
        },
        ...(isSelected && {
          borderLeft: 2,
          borderColor: 'primary.main'
        })
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-segment-id={segment.id}
    >
      <Box 
        className="segment-content"
        sx={{ 
          display: 'flex',
          alignItems: 'stretch',
          p: 0,
          minHeight: '64px'
        }}
      >
        {dragHandleProps && (
          <Box 
            className="drag-handle"
            {...dragHandleProps}
            sx={{ 
              opacity: 0,
              transition: theme.transitions.create('opacity'),
              cursor: 'grab',
              '&:active': {
                cursor: 'grabbing'
              },
              px: 2,
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'stretch',
              position: 'relative',
              userSelect: 'none',
              touchAction: 'none',
              '&:hover': {
                opacity: 1,
                backgroundColor: theme.palette.action.hover
              }
            }}
          >
            <DragIndicatorIcon color="action" />
          </Box>
        )}
        
        <Box 
          className="segment-text"
          sx={{ 
            flexGrow: 1,
            borderLeft: 3,
            borderColor: speakerColor.colors.edgeLine,
            pl: 2,
          }}
        >
          {/** 
           * Speaker label is NOT editable now! 
           * userSelect: 'none' will also prevent text highlighting 
           * If you'd like them to be able to highlight (but not edit), 
           * just remove userSelect: 'none'. 
           */}
          <Typography 
            variant="subtitle2"
            contentEditable={false}
            sx={{ 
              color: 'primary.main',
              textTransform: 'uppercase',
              mb: 1,
              userSelect: 'none',      // prevents highlighting (optional)
              pointerEvents: 'none'    // ensures clicks pass through (optional)
            }}
          >
            {segment.speaker}
          </Typography>

          <Box 
            className="words-container"
            sx={{
              position: 'relative',
              // This region is still contentEditable, via PapercutContent
              // so the caret can move freely among words:
              '& .word': {
                transition: theme.transitions.create('background-color', {
                  duration: '0.1s'
                })
              }
            }}
          >
            {segment.words.map((word, idx) => (
              <SegmentWord
                key={word.id}
                word={word}
                segment={segment}
                onWordHover={onWordHover}
                highlightStyle={getHighlightStyle?.(segment.id, word.id)}
                nextWord={segment.words[idx + 1]}
                isFirstInSegment={idx === 0}
                isActive={word.id === activeWordId}
                onClick={handleWordClick}
              />
            ))}
          </Box>
        </Box>
      </Box>

      {isHovered && (
        <Box
          onClick={handleDeleteClick}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            opacity: 0.7,
            cursor: 'pointer',
            '&:hover': {
              opacity: 1
            },
            zIndex: 1
          }}
        >
          <DeleteIcon color="error" />
        </Box>
      )}
    </AnimatedPaper>
  );
}));

Segment.displayName = 'Segment';

export default Segment;
