import React, { useState } from 'react';
import { 
  Box, 
  Paper,
  Tabs,
  Tab,
  Typography,
  SvgIcon,
  IconButton,
  Tooltip
} from '@mui/material';
import PapercutViewer from './index';
import { usePapercuts } from '../../../contexts/PapercutContext';
import { usePapercutActions } from '../../../hooks/usePapercut/usePapercutActions';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import IosShareIcon from '@mui/icons-material/IosShare';
import { DragDropContext } from 'react-beautiful-dnd';


const PapercutViewerSection = ({ transcriptData }) => {
  const { papercuts, activeTab, updatePapercutContent } = usePapercuts();
  const { insertToPapercut } = usePapercutActions();
  const [isDragOver, setIsDragOver] = useState(false);



  const CustomIcon = ({ Icon, color = 'primary.main' }) => (
    <SvgIcon
      component={Icon}
      inheritViewBox
      sx={{
        width: 24,
        height: 24,
        '& path, & rect': {
          stroke: color,
        },
      }}
    />
  );

  const activePapercut = papercuts.find(p => p.id === activeTab);

  const handleDragOver = (e) => {
    if (e.dataTransfer.types.includes('application/transcript-selection')) {
      e.preventDefault();
      setIsDragOver(true);
    }
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    try {
      const data = e.dataTransfer.getData('application/transcript-selection');
      if (data && activeTab) {
        const selectedContent = JSON.parse(data);
        insertToPapercut(activeTab, selectedContent);
      }
    } catch (error) {
      console.error('Error processing drop:', error);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const papercut = papercuts.find(p => p.id === activeTab);
    if (!papercut) return;

    const items = Array.from(papercut.content);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);

    updatePapercutContent(activeTab, items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Paper
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{ 
          flexGrow: 1,
          display: 'flex', 
          flexDirection: 'column',
          bgcolor: 'background.default',
          overflow: 'hidden',
          height: '100%',
          '& > *': { margin: 0 },
          border: isDragOver ? '2px dashed' : '2px solid transparent',
          borderColor: isDragOver ? 'primary.main' : 'transparent',
        }}
      >
        <Box sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          bgcolor: 'background.paper',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
          flexShrink: 0 
        }}>
          <Tabs 
            value={activeTab ? 1 : 0}
            sx={{ 
              minHeight: 48,
              flexGrow: 1,
              '& .MuiTab-root': {
                minHeight: 48,
                color: 'text.secondary'
              }
            }}
          >
            <Tab
              icon={<CustomIcon Icon={PlayCircleOutlineIcon} alt="Play" color="text.disabled" />}
              iconPosition="start"
              label="VIDEO"
              disabled
              sx={{
                opacity: 0.5,
                '&.Mui-disabled': {
                  color: 'text.disabled'
                },
                '& .MuiTab-iconWrapper': {
                  marginRight: 1
                }
              }}
            />
            <Tab
              icon={<CustomIcon Icon={DescriptionIcon} alt="Transcript" />}
              iconPosition="start"
              label="PAPERCUT"
              sx={{
                '& .MuiTab-iconWrapper': {
                  marginRight: 1
                }
              }}
            />
          </Tabs>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {activePapercut && (
              <>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary', 
                    fontSize: '0.9rem'
                  }}
                >
                  {activePapercut.name}
                </Typography>
                
                <Tooltip title="Export to Word">
                  <IconButton 
                    onClick={() => {
                      const htmlContent = `
                        <html xmlns:w="urn:schemas-microsoft-com:office:word">
                        <head>
                        <meta charset="utf-8">
                        <style>
                          .speaker { font-weight: bold; margin-top: 12px; }
                          .segment { margin-bottom: 12px; }
                        </style>
                        </head>
                        <body>
                        <div style="width: 100%; max-width: 800px; margin: 0 auto;">
                          <h1>${activePapercut.name || 'Papercut Export'}</h1>
                          ${activePapercut.content.map(segment => `
                            <div class="segment">
                              <div class="speaker">${segment.speaker || 'Speaker'}:</div>
                              <div>${segment.words.map(word => word.text).join(' ')}</div>
                            </div>
                          `).join('')}
                        </div>
                        </body>
                        </html>
                      `;
                      
                      const blob = new Blob([htmlContent], { type: 'application/msword' });
                      const url = URL.createObjectURL(blob);
                      const link = document.createElement('a');
                      link.href = url;
                      link.download = `${activePapercut.name || 'papercut'}.doc`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                      URL.revokeObjectURL(url);
                    }}
                    size="small"
                    sx={{
                      color: 'primary.main',
                      '&:hover': {
                        bgcolor: 'action.hover'
                      }
                    }}
                  >
                    <IosShareIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ 
          flexGrow: 1,
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <PapercutViewer transcriptData={transcriptData || []} />
        </Box>
      </Paper>
    </DragDropContext>
  );
};

export default PapercutViewerSection;