// src/contexts/FocusContext.js
import React, { createContext, useContext, useState } from 'react';
import { FocusTypes } from '../types/FocusTypes';

const FocusContext = createContext(null);

export function FocusProvider({ children }) {
  // Initial focus is on the bin, no specific resource, no subsection
  const [currentFocus, setCurrentFocus] = useState({
    type: FocusTypes.BIN,
    resourceId: null,
    subSection: null,
  });

  // Macro-level focus setters (no subSection)
  const focusBin = () => setCurrentFocus({ type: FocusTypes.BIN, resourceId: null, subSection: null });
  const focusTranscript = (fileId) => setCurrentFocus({ type: FocusTypes.TRANSCRIPT, resourceId: fileId, subSection: null });
  const focusPapercut = (papercutId) => setCurrentFocus({ type: FocusTypes.PAPERCUT, resourceId: papercutId, subSection: null });

  // Micro-level focus control: set subSection within current type
  const setFocusSubSection = (subSection) => {
    // Update only the subSection, keeping the current type and resourceId
    setCurrentFocus(prev => ({
      ...prev,
      subSection
    }));
  };

  // In the future, if you add more macro areas:
  // const focusTimeline = (timelineId) => setCurrentFocus({ type: FocusTypes.TIMELINE, resourceId: timelineId, subSection: null });

  return (
    <FocusContext.Provider value={{
      currentFocus,
      setCurrentFocus,
      focusBin,
      focusTranscript,
      focusPapercut,
      setFocusSubSection
    }}>
      {children}
    </FocusContext.Provider>
  );
}

export const useFocus = () => {
  const context = useContext(FocusContext);
  if (!context) {
    throw new Error('useFocus must be used within a FocusProvider');
  }
  return context;
};

export default FocusContext;
