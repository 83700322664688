import React from 'react';
import { Box } from '@mui/material';
import Sidebar from './Sidebar';
import MediaSidebar from '../Media/MediaSidebar';
import BinViewerSection from '../Viewers/BinViewerSection';
import PapercutViewerSection from '../Viewers/PapercutViewer/PapercutViewerSection';
import ChatBot from '../Chatbot/ChatBot';

const MainLayout = ({
  themeMode,
  onThemeChange,
  currentView,
  onViewChange,
  mediaFiles,
  onFileUpload,
  selectedBinClip,
  transcripts,
  chatMessages,
  onSendMessage
}) => {
  return (
    <Box sx={{ 
      display: 'grid',
      height: '100vh',
      gridTemplateRows: '1fr',
      gridTemplateColumns: '72px 250px 1fr 1fr',
      gridGap: '2px',
      overflow: 'hidden',
      position: 'relative', // Add this to ensure proper stacking context
    }}>
      {/* Left Sidebar */}
      <Box sx={{ gridRow: '1', gridColumn: '1' }}>
        <Sidebar
          onPageChange={onViewChange}
          currentPage={currentView}
          themeMode={themeMode}
          onThemeChange={onThemeChange}
        />
      </Box>

      {/* Media Sidebar Container */}
      <Box sx={{ 
        gridRow: '1', 
        gridColumn: '2',
        position: 'relative', // Add this for ChatBot positioning
        overflow: 'hidden'
      }}>
        <MediaSidebar
          files={mediaFiles}
          onFileUpload={onFileUpload}
          selectedFile={selectedBinClip}
        />
        
        {/* ChatBot - Will be positioned relative to this container */}
        <ChatBot
          containerSx={{  // Changed from sx to containerSx for clarity
            '& .MuiPaper-root': {
              position: 'absolute !important',
              left: '0 !important',
              right: '0 !important',
              width: '100% !important',
              margin: '0 !important',
              maxWidth: 'none !important',
            }
          }}
          clips={[]}
          messages={chatMessages}
          onSendMessage={onSendMessage}
          selectedBinClip={selectedBinClip}
          transcriptData={selectedBinClip ? transcripts.get(selectedBinClip.name) : null}
          timelineState={{
            clips: [],
            totalDuration: 0,
            settings: {}
          }}
          timelineRows={[]}
          setTimelineRows={() => {}}
          onClipsChange={() => {}}
        />
      </Box>

      {/* Bin Viewer */}
      <Box sx={{ gridRow: '1', gridColumn: '3', overflow: 'hidden' }}>
        <BinViewerSection />
      </Box>

      {/* Papercut Viewer */}
      <Box sx={{ gridRow: '1', gridColumn: '4', overflow: 'hidden' }}>
        <PapercutViewerSection transcriptData={transcripts} />
      </Box>
    </Box>
  );
};

export default MainLayout;