import React, { useState } from 'react';
import { Box, Typography, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import { ChevronRight, ExpandMore, MoreVert as MoreVertIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFileSystem } from '../../contexts/FileSystemContext';
import FileTypeIcon from './FileTypeIcon';
import { useFocus } from '../../contexts/FocusContext';
import { FocusTypes } from '../../types/FocusTypes';

const FileItem = ({ file, depth = 0, onDragOver, isExpanded, onToggleExpand }) => {
  const { renameItem, deleteItem, handleSelection, selectedItems, activePapercutId } = useFileSystem();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState(file.name);
  const { currentFocus } = useFocus();


  const isPapercut = file.type === 'papercut';
  const isSelected = isPapercut 
  ? file.id === activePapercutId
  : selectedItems.includes(file.id) && file.item_type === 'file';  // Add type check

  const getDisplayName = (filename) => {
    if (file.type === 'folder') return filename;
    return filename.replace(/\.[^/.]+$/, "");
  };

  const getFullName = (displayName) => {
    if (file.type === 'folder') return displayName;
    const extension = file.name.match(/\.[^/.]+$/)?.[0] || '';
    return `${displayName}${extension}`;
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRename = () => {
    setIsRenaming(true);
    handleMenuClose();
  };

  const handleRenameSubmit = () => {
    const fullName = getFullName(newName);
    renameItem(file.id, fullName);
    setIsRenaming(false);
  };

  const handleDelete = () => {
    deleteItem(file.id);
    handleMenuClose();
  };


  const handleDragStart = (e) => {
    e.stopPropagation();
    // Include item type in the drag data
    e.dataTransfer.setData('text', file.id);
    e.dataTransfer.setData('itemType', file.item_type);
    if (!isSelected) {
      handleSelection(file.id, e);
    }
  };

  const handleIconClick = (event) => {
    event.stopPropagation();
    if (file.type === 'folder') {
      onToggleExpand(file.id);
    }
  };

  const handleNameClick = (event) => {
    if (!event.defaultPrevented) {
      event.stopPropagation();
      // Ensure single-click selection works for papercuts and other file types
      handleSelection(file.id, event, file.type === 'papercut');
    }
  };
  


  const getSelectionLevelStyles = () => {
    if (!isSelected) {
      return {
        bgColor: 'transparent',
        textColor: 'text.primary',
        highlightWidth: '0%',
      };
    }
  
    switch (currentFocus.type) {
      case FocusTypes.BIN:
        return {
          bgColor: '#9c27b0',
          textColor: 'common.white',
          highlightWidth: '100%',
        };
      case FocusTypes.TRANSCRIPT:
        return {
          bgColor: 'action.selected',
          textColor: 'text.primary',
          highlightWidth: '100%'
        };
      case FocusTypes.PAPERCUT:
        if (currentFocus.resourceId === file.id) {
          return {
            bgColor: 'action.selected',
            textColor: 'text.primary',
            highlightWidth: '100%'
          };
        } else {
          return {
            bgColor: 'action.hover',
            textColor: 'text.secondary',
            highlightWidth: '75%'
          };
        }
      default:
        return {
          bgColor: 'action.hover',
          textColor: 'text.secondary',
          highlightWidth: '75%'
        };
    }
  };

  const { bgColor, textColor, highlightWidth } = getSelectionLevelStyles();

   return (
    <Box
      draggable
      onDragStart={handleDragStart}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onDragOver) onDragOver(e);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        py: 0.5,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: bgColor === 'transparent' ? 'action.hover' : 'transparent',
        },
        color: textColor,
        width: '100%',
      }}
      onClick={handleNameClick}
    >
      {/* Background highlight - stays the same */}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: highlightWidth,
          backgroundColor: bgColor,
          zIndex: 0,
          pointerEvents: 'none',
          transition: 'width 0.2s ease-in-out',
        }}
      />
      
      {/* Content container - add marginLeft for indentation */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          zIndex: 1,
          width: '100%',
          gap: 1,
          pr: 1,
        }}
      >
        {/* Rest of the content stays exactly the same */}
        {file.type === 'folder' && (
          <IconButton
            size="small"
            onClick={handleIconClick}
            sx={{ p: 0.5 }}
          >
            {isExpanded ? <ExpandMore fontSize="small" /> : <ChevronRight fontSize="small" />}
          </IconButton>
        )}

        <FileTypeIcon type={file.type} />

        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          {isRenaming ? (
            <TextField
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onBlur={handleRenameSubmit}
              onKeyPress={(e) => e.key === 'Enter' && handleRenameSubmit()}
              size="small"
              autoFocus
              sx={{ fontSize: '0.875rem' }}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{
                fontWeight: isSelected ? 500 : 400,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {getDisplayName(file.name)}
            </Typography>
          )}
        </Box>

        <Box sx={{ ml: 'auto', flexShrink: 0 }}>
          <IconButton 
            size="small" 
            onClick={handleMenuOpen}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleRename}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Rename
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default FileItem;