import React, { useState, useCallback } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { usePapercuts } from '../../../contexts/PapercutContext';
import { useFocus } from '../../../contexts/FocusContext';
import { MetadataPanel } from './MetadataPanel';
import { ScrollContainer } from './ScrollContainer';
import ActivePapercut from './ActivePapercut';
import PapercutContent from './PapercutContent';

const PapercutViewer = ({ transcriptData }) => {
  const { papercuts, activeTab, updatePapercutContent } = usePapercuts();
  const activePapercut = papercuts.find(p => p.id === activeTab);
  const [hoveredWord, setHoveredWord] = useState(null);
  const { focusPapercut } = useFocus();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleViewerClick = useCallback(() => {
    const resourceId = activePapercut ? activePapercut.id : null;
    focusPapercut(resourceId);
  }, [activePapercut, focusPapercut]);

  const handleScroll = useCallback((event) => {
    setScrollPosition(event.target.scrollTop);
  }, []);

  const handleContentChange = useCallback((newContent) => {
    if (activePapercut) {
      updatePapercutContent(activePapercut.id, newContent);
    }
  }, [activePapercut, updatePapercutContent]);

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.paper',
        overflow: 'hidden',
      }}
      onClick={handleViewerClick}
    >
      <ActivePapercut
        name={activePapercut ? activePapercut.name : null}
        sx={{ flexShrink: 0 }}
      />

      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: 0,
      }}>
        <Box sx={{ 
          position: 'relative',
          flex: 1,
          minHeight: 0
        }}>
          <ScrollContainer onScroll={handleScroll}>
            {activePapercut ? (
              <PapercutContent
                papercutId={activePapercut.id}
                transcriptData={transcriptData || []}
                onWordHover={setHoveredWord}
                onContentChange={handleContentChange}
              />
            ) : (
              <Box sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Typography variant="body1" sx={{ color: 'text.disabled', fontStyle: 'italic' }}>
                  Create a Papercut
                </Typography>
              </Box>
            )}
          </ScrollContainer>
        </Box>

        <MetadataPanel
          hoveredWord={hoveredWord}
          content={activePapercut?.content || []}
          sx={{ 
            flexShrink: 0,
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'background.paper',
            boxShadow: scrollPosition > 0 ? 1 : 0,
            transition: 'box-shadow 0.2s ease-in-out',
            zIndex: 1
          }}
        />
      </Box>
    </Card>
  );
};

export default PapercutViewer;