import React, { createContext, useCallback, useState } from 'react';

export const PapercutClipboardContext = createContext();

export const PapercutClipboardProvider = ({ children }) => {
  const [clipboardContent, setClipboardContent] = useState(null);
  const [clipboardSource, setClipboardSource] = useState(null);

  const storeInPapercutClipboard = useCallback((content, source) => {
    setClipboardContent(content);
    setClipboardSource(source);
    const plainText = content
      .map(seg => seg.words.map(w => w.text).join(' '))
      .join('\n');
    navigator.clipboard.writeText(plainText).catch(console.error);
  }, []);

  const getFromPapercutClipboard = useCallback(() => {
    return { content: clipboardContent, source: clipboardSource };
  }, [clipboardContent, clipboardSource]);

  return (
    <PapercutClipboardContext.Provider value={{ storeInPapercutClipboard, getFromPapercutClipboard }}>
      {children}
    </PapercutClipboardContext.Provider>
  );
};
