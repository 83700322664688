import React, { useState, useEffect } from 'react';
import { 
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Paper,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import { 
  KeyboardArrowDown, 
  KeyboardArrowUp
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { promptTemplates } from './promptTemplates';
import { sendToLLM, sendToLlama, setAuthToken } from './Api';
import { useTranscript } from '../../contexts/TranscriptContext';
import { useAuth } from '../../contexts/AuthContext';
import { usePapercutActions } from '../../hooks/usePapercut/usePapercutActions';
import { usePapercuts } from '../../contexts/PapercutContext';

const ChatBot = ({ 
  containerSx,
  onSendMessage, 
  messages, 
  selectedBinClip, 
  transcriptData,
  onAddToTimeline,
  timelineState,
  timelineRows = [{ rowId: 0, clips: [], lastEnd: 0 }],
  setTimelineRows,
  onClipsChange
}) => {
  const [input, setInput] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const theme = useTheme();
  const { insertFromLLM } = usePapercutActions(); 
  const { activeTab } = usePapercuts();
  const { highlightedContent } = useTranscript();
  const { token } = useAuth();

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmit = async (e, useGPT = true) => {
    e.preventDefault();
  
    if (!input.trim() || !selectedTemplate) return;
    setIsLoading(true);
  
    onSendMessage({
      text: input,
      sender: 'user',
      template: selectedTemplate
    });
  
    try {
      const templateContent = promptTemplates.find(t => t.name === selectedTemplate)?.template;
      if (!templateContent) {
        throw new Error('Selected template not found');
      }
  
      const wordTimingJson = (highlightedContent && highlightedContent.length > 0)
        ? JSON.stringify(highlightedContent)
        : '';
  
      let response;
      if (useGPT) {
        response = await sendToLLM(wordTimingJson, templateContent, input, 'chat');
      } else {
        response = await sendToLlama(wordTimingJson, templateContent, input, 'chat');
      }
      
      await insertFromLLM(activeTab, response);
      setInput('');
  
    } catch (error) {
      console.error('Chat error:', error);
      onSendMessage({
        text: `Error: ${error.message}. Please try a different prompt.`,
        sender: 'bot',
        isError: true
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <Paper
        elevation={3}
        sx={{
          position: 'fixed',
          bottom: isExpanded ? 0 : '48px',
          left: 0,
          width: '300px',
          height: isExpanded ? '400px' : '48px',
          backgroundColor: theme.palette.background.paper,
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1200,
          transition: 'all 0.3s ease',
          borderRadius: '8px 8px 0 0',
          cursor: !isExpanded ? 'pointer' : 'default',
          ...(containerSx || {}) // Spread sx prop if it exists, otherwise empty object
        }}
        onClick={() => !isExpanded && setIsExpanded(true)}
      >
        <Box sx={{ 
          p: 1.5,
          borderBottom: isExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '8px 8px 0 0'
        }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
            Papercut Assistant
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {isLoading && <CircularProgress size={20} color="inherit" />}
            <IconButton 
              size="small"
              onClick={() => setIsExpanded(!isExpanded)}
              sx={{ color: 'inherit' }}
            >
              {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
          </Box>
        </Box>

        {isExpanded && (
          <>
            <Box 
              sx={{ 
                flex: 1,
                overflow: 'auto',
                p: 2,
                backgroundColor: theme.palette.background.default,
                display: 'flex',
                flexDirection: 'column',
                gap: 1
              }}
            >
              {messages.map((msg, index) => (
                <Paper
                  key={index}
                  elevation={1}
                  sx={{
                    p: 1.5,
                    maxWidth: '85%',
                    alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                    backgroundColor: msg.sender === 'user' 
                      ? theme.palette.primary.main
                      : msg.isError 
                        ? theme.palette.error.light
                        : msg.isSuccess 
                          ? theme.palette.success.light
                          : theme.palette.grey[100],
                    color: msg.sender === 'user' 
                      ? theme.palette.primary.contrastText
                      : theme.palette.text.primary
                  }}
                >
                  <Typography variant="body2">
                    {msg.text}
                  </Typography>
                </Paper>
              ))}
            </Box>

            <Box 
              component="form" 
              onSubmit={(e) => handleSubmit(e, true)}
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
                borderTop: 1,
                borderColor: 'divider',
                backgroundColor: theme.palette.background.paper
              }}
            >
              <FormControl size="small" fullWidth>
                <Select
                  value={selectedTemplate}
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                  displayEmpty
                  disabled={isLoading}
                >
                  <MenuItem value="">Select a template</MenuItem>
                  {promptTemplates.map(template => (
                    <MenuItem key={template.name} value={template.name}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                size="small"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
                disabled={isLoading}
                multiline
                maxRows={3}
                fullWidth
              />

              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button 
                  onClick={(e) => handleSubmit(e, true)}
                  variant="contained" 
                  disabled={!input.trim() || !selectedTemplate || isLoading}
                  fullWidth
                  size="small"
                >
                  GPT
                </Button>
                <Button 
                  onClick={(e) => handleSubmit(e, false)}
                  variant="outlined"
                  disabled={!input.trim() || !selectedTemplate || isLoading}
                  fullWidth
                  size="small"
                >
                  Llama
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Paper>
    </Slide>
  );
};

export default ChatBot;
