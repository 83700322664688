import React from 'react';
import { Box, Typography } from '@mui/material';

const WordMetadata = ({ word, segment, fileId }) => {
  const formatTime = (time) => {
    if (!time) return 'Unknown';
    const date = new Date(0);
    date.setSeconds(time);
    return date.toISOString().substr(11, 8);
  };

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        px: 2,
        py: 2,
        bgcolor: 'background.paper',
        width: '100%'
      }}
    >
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        <Typography variant="body2">
          Word: {word.text}
        </Typography>
        <Typography variant="body2">
          Segment: {formatTime(segment.startTime)} - {formatTime(segment.endTime)}
        </Typography>
        <Typography variant="body2">
          File: {fileId || 'Unknown File'}
        </Typography>
      </Box>
    </Box>
  );
};

export const MetadataPanel = ({ hoveredWord, content }) => {
  const segment = hoveredWord && content.find(s => s.id === hoveredWord.segmentId);
  const word = segment?.words.find(w => w.id === hoveredWord.wordId);

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '100px',
        borderTop: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        zIndex: 10,
        display: 'flex',
        transform: 'translateZ(0)',
      }}
    >
      {/* Vertical bar alignment spacer */}
      <Box sx={{ width: '4px', flexShrink: 0 }} />
      
      {/* Content container aligned with transcript content */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          opacity: hoveredWord ? 1 : 0.7,
          transition: 'opacity 0.2s ease',
        }}
      >
        {hoveredWord && word ? (
          <WordMetadata
            word={word}
            segment={segment}
            fileId={segment?.sourceReference?.fileId}
          />
        ) : (
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              fontStyle: 'italic',
              px: 2
            }}
          >
            Hover over text to see word metadata
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MetadataPanel;