import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { usePapercuts } from '../../../contexts/PapercutContext';
import { usePapercutActions } from '../../../hooks/usePapercut/usePapercutActions';
import { usePapercutSelection } from '../../../hooks/usePapercut/usePapercutSelection';
import { usePapercutClipboard } from '../../../hooks/usePapercut/usePapercutClipboard';
import { usePapercutKeyboard } from '../../../hooks/usePapercut/usePapercutKeyboard';
import { useSpeakerColors } from '../../../contexts/SpeakerColorContext';
import EmptyState from './EmptyState';
import CopySnackbar from '../../../components/common/CopySnackbar';
import Segment from './Segment';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { getCursorWordContext } from '../../../utils/papercutUtils/cursorWordUtils';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: 0,
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  padding: grid,
  width: '100%',
  transition: 'background-color 0.2s ease'
});

// Navigation keys that should always be allowed
const ALLOWED_KEYS = [
  'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
  'Home', 'End', 'PageUp', 'PageDown',
  'Tab', 'Shift', 'Control', 'Alt', 'Meta',
  'CapsLock', 'Escape'
];

// Shortcut keys that should be allowed when used with Ctrl/Cmd
const ALLOWED_SHORTCUTS = ['c', 'v', 'x', 'z'];

const PapercutContent = ({
  papercutId,
  onWordHover,
  onContentUpdate,
  transcriptData,
  getHighlightStyle
}) => {
  const theme = useTheme();
  const { getSpeakerColor } = useSpeakerColors();
  const {
    papercuts,
    updatePapercutContent,
    activeTab,
    updateCursorPosition
  } = usePapercuts();
  const {
    insertToPapercut,
    deleteWordAtCursor,
    splitSegmentAtCursor,
    undo,
    redo,
    canUndo,
    canRedo
  } = usePapercutActions();

  const [copyFeedback, setCopyFeedback] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const containerRef = useRef(null);

  const papercut = papercuts.find(p => p.id === papercutId);
  const content = useMemo(() => {
    return Array.isArray(papercut?.content) ? papercut.content : [];
  }, [papercut]);

  const { selection, getSelectedContent, clearSelection } = usePapercutSelection(content);
  const { copyPapercutContent, pastePapercutContent } = usePapercutClipboard('papercut');

  const [recentlyInsertedSegmentId, setRecentlyInsertedSegmentId] = useState(null);

  const operations = {
    undo,
    redo,
    canUndo,
    canRedo,
    deleteWordAtCursor: () => {
      const newContent = deleteWordAtCursor(content);
      updatePapercutContent(papercutId, newContent);
    },
    splitSegmentAtCursor: () => {
      const newContent = splitSegmentAtCursor(content);
      updatePapercutContent(papercutId, newContent);
    },
    handleContentUpdate: (newContent) => {
      updatePapercutContent(papercutId, newContent);
    }
  };

  const getUpdatedHighlightStyle = useCallback(
    (segmentId, wordId) => {
      const baseStyle = getHighlightStyle?.(segmentId, wordId) || {};
      return {
        ...baseStyle,
        position: 'relative',
        '&:hover': {
          backgroundColor: theme.palette.action.hover
        },
        '&::selection': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText
        }
      };
    },
    [getHighlightStyle, theme.palette]
  );

  const handlers = {
    getSelectedContent,
    clearSelection
  };

  const keyboardHandler = usePapercutKeyboard({
    content,
    selection,
    handlers,
    operations
  });

  const preventDefaultInput = useCallback((e) => {
    // Allow navigation keys
    if (ALLOWED_KEYS.includes(e.key)) return;

    // Allow keyboard shortcuts
    if ((e.ctrlKey || e.metaKey) && ALLOWED_SHORTCUTS.includes(e.key)) return;

    // Prevent all other inputs
    e.preventDefault();
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      preventDefaultInput(event);
      keyboardHandler(event);
    },
    [keyboardHandler, preventDefaultInput]
  );

  const handleBeforeInput = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleCompositionStart = useCallback((e) => {
    e.preventDefault();
  }, []);

  // Add this effect to listen for copy
  useEffect(() => {
    const handleCopy = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c' && selection) {
        event.preventDefault();
        const selectedSegments = getSelectedContent();
        if (selectedSegments && selectedSegments.length > 0) {
          const success = copyPapercutContent(selectedSegments);
          if (success) {
            setCopyFeedback(true);
            setTimeout(() => setCopyFeedback(false), 1500);
          }
        }
      }
    };
    document.addEventListener('keydown', handleCopy);
    return () => document.removeEventListener('keydown', handleCopy);
  }, [selection, getSelectedContent, copyPapercutContent, clearSelection]);

 // Add this effect to listen for paste
useEffect(() => {
  const handlePaste = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      const cursorContext = getCursorWordContext();
      
      const success = pastePapercutContent(({ content: pastedContent }) => {
        if (activeTab && pastedContent && pastedContent.length > 0) {
            const insertedSegmentId = insertToPapercut(activeTab, pastedContent, cursorContext);
            if (insertedSegmentId) {
            setRecentlyInsertedSegmentId(insertedSegmentId);
            }
        }
      });
      
      if (success) {
        event.preventDefault();
      }
    }
  };
  
  document.addEventListener('keydown', handlePaste);
  return () => document.removeEventListener('keydown', handlePaste);
}, [pastePapercutContent, activeTab, insertToPapercut]);



  const handleDragOver = useCallback((e) => {
    if (e.dataTransfer.types.includes('application/transcript-selection')) {
      e.preventDefault();
      setIsDragOver(true);
    }
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

const handleDrop = useCallback(
  (e) => {
    e.preventDefault();
    setIsDragOver(false);
    try {
      const data = e.dataTransfer.getData('application/transcript-selection');
      if (data && activeTab) {
        const selectedContent = JSON.parse(data);
        const cursorContext = getCursorWordContext();
        insertToPapercut(activeTab, selectedContent, cursorContext);
      }
    } catch (error) {
      console.error('Error processing drop:', error);
    }
  },
  [activeTab, insertToPapercut]
);
  const handleDeleteSegment = useCallback(
    (segmentId) => {
      const newContent = content.filter((s) => s.id !== segmentId);
      updatePapercutContent(papercutId, newContent);
    },
    [content, updatePapercutContent, papercutId]
  );

  const handleWordHover = useCallback(
    (wordInfo) => {
      onWordHover?.(wordInfo);
    },
    [onWordHover]
  );

  // Ensure the container is focusable for keyboard events
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus();
    }
  }, []);

  // Listen for selectionchange to track caret position
  useEffect(() => {
    const handleSelectionChange = () => {
      const ctx = getCursorWordContext();
      updateCursorPosition(ctx || null);
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [updateCursorPosition]);

  return (
    <Box
      ref={containerRef}
      spellCheck={false}
      contentEditable
      suppressContentEditableWarning
      sx={{
        userSelect: 'text',
        outline: 'none',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        border: isDragOver ? '2px dashed' : '2px solid transparent',
        borderColor: isDragOver ? 'primary.main' : 'transparent',
        p: 2
      }}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onBeforeInput={handleBeforeInput}
      onPaste={handlePaste}
      onCompositionStart={handleCompositionStart}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {content.length === 0 ? (
        <EmptyState />
      ) : (
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {content.map((segment, index) => (
                <Draggable
                  key={segment.id}
                  draggableId={segment.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Segment
                        content={content}
                        segmentIndex={index}
                        segment={segment}
                        theme={theme}
                        getSpeakerColor={getSpeakerColor}
                        onWordHover={handleWordHover}
                        onDeleteSegment={handleDeleteSegment}
                        getHighlightStyle={getUpdatedHighlightStyle}
                        dragHandleProps={provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        isNewlyInserted={recentlyInsertedSegmentId === segment.id}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
      <CopySnackbar open={copyFeedback} onClose={() => setCopyFeedback(false)} />
    </Box>
  );
};

export default PapercutContent;