import { useCallback } from 'react';
import { usePapercuts } from '../../contexts/PapercutContext';
import { v4 as uuidv4 } from 'uuid';
import { 
  findSelectedWord,
  moveCursorToNextWord
} from '../../utils/papercutUtils/cursorUtils';
import { useFileSystem } from '../../contexts/FileSystemContext';

function scrollToWord(wordId) {
  requestAnimationFrame(() => {
    const element = document.querySelector(`[data-word-id="${wordId}"]`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
  });
}

export function usePapercutActions() {
  const { 
    addContentToPapercut,
    updatePapercutContent,  
    papercuts,
    lastCursorPosition,    
    createNewPapercut,
    setActiveTab,
    updateCursorPosition  // Add this
  } = usePapercuts();
  const { fetchFiles } = useFileSystem();

  const safeParseIfJson = (value) => {
    if (typeof value === 'string') {
      const trimmed = value.trim();
      if (
        (trimmed.startsWith('{') && trimmed.endsWith('}')) ||
        (trimmed.startsWith('[') && trimmed.endsWith(']'))
      ) {
        try {
          return JSON.parse(value);
        } catch (e) {
          console.warn('Failed to parse JSON-like string:', value, e);
          return value;
        }
      }
    }
    return value;
  };

  const transformSegment = useCallback((segment, index) => {
    let words = segment.words;
    if (typeof words === 'string') {
      words = safeParseIfJson(words);
    }
    if (!Array.isArray(words)) {
      words = [];
    }

    const fileId = safeParseIfJson(segment.fileId) || segment.fileId;
    const segmentId = safeParseIfJson(segment.globalIndex) || safeParseIfJson(segment.id) || segment.id;

    const transformedSegment = {
      id: uuidv4(),
      speaker: segment.speaker,
      startTime: segment.start_time || segment.startTime,
      endTime: segment.end_time || segment.endTime,
      words: words.map((word, wordIndex) => ({
        id: uuidv4(),
        text: word.word || word.text,
        startTime: word.start || word.startTime,
        endTime: word.end || word.endTime,
        index: wordIndex
      })),
      sourceReference: {
        fileId: fileId,
        segmentId: segmentId,
        index: index
      }
    };

    return transformedSegment;
  }, []);

  const mergeSegmentsWithSameSpeaker = useCallback((segments) => {
    return segments.reduce((acc, current) => {
      if (acc.length === 0) return [current];
      const lastSegment = acc[acc.length - 1];
      
      if (lastSegment.speaker === current.speaker) {
        const mergedSegment = {
          ...lastSegment,
          endTime: current.endTime,
          words: [
            ...lastSegment.words,
            ...current.words.map((word, idx) => ({
              ...word,
              index: lastSegment.words.length + idx
            }))
          ],
          sourceReference: {
            ...lastSegment.sourceReference,
            wordRange: [
              lastSegment.sourceReference?.wordRange?.[0] || 0,
              lastSegment.words.length + current.words.length
            ]
          }
        };
        return [...acc.slice(0, -1), mergedSegment];
      }

      return [...acc, current];
    }, []);
  }, []);

  const deleteWordAtCursor = (content) => {
    const selectedWord = findSelectedWord();
    if (!selectedWord) return content;
  
    const { segmentId, wordId } = selectedWord;
    const segmentIndex = content.findIndex(s => s.id === segmentId);
    if (segmentIndex === -1) return content;
  
    const segment = content[segmentIndex];
    const wordIndex = segment.words.findIndex(w => w.id === wordId);
    if (wordIndex === -1) return content;
  
    const wordElement = document.querySelector(`[data-word-id="${wordId}"]`);
    const selection = window.getSelection();
    const isAtStart = selection?.anchorOffset === 0;
  
    // If we're at the start of a word
    if (isAtStart) {
      // If this is the first word in the segment
      if (wordIndex === 0) {
        // If we have a previous segment
        if (segmentIndex > 0) {
          const previousSegment = content[segmentIndex - 1];
          
          // If same speaker, merge segments
          if (previousSegment.speaker === segment.speaker) {
            const mergedSegment = {
              ...previousSegment,
              endTime: segment.endTime,
              words: [
                ...previousSegment.words,
                ...segment.words.map((word, idx) => ({
                  ...word,
                  index: previousSegment.words.length + idx
                }))
              ]
            };
  
            const newContent = [
              ...content.slice(0, segmentIndex - 1),
              mergedSegment,
              ...content.slice(segmentIndex + 1)
            ];
  
            // Move cursor to the merge point
            const mergePointWord = document.querySelector(
              `[data-word-id="${previousSegment.words[previousSegment.words.length - 1].id}"]`
            );
            if (mergePointWord) {
              moveCursorToNextWord(mergePointWord, 'forward');
            }
  
            return newContent;
          }
  
          // Different speakers - delete last word of previous segment
          const updatedPrevWords = previousSegment.words
            .slice(0, -1)
            .map((w, idx) => ({ ...w, index: idx }));
  
          // Even if previous segment becomes empty, keep it
          const updatedPrevSegment = {
            ...previousSegment,
            words: updatedPrevWords,
            endTime: updatedPrevWords[updatedPrevWords.length - 1]?.endTime || previousSegment.endTime,
          };
  
          const newContent = [
            ...content.slice(0, segmentIndex - 1),
            updatedPrevSegment,
            ...content.slice(segmentIndex)
          ];
  
          // Move cursor to end of updated previous segment
          if (updatedPrevWords.length > 0) {
            const lastWord = document.querySelector(
              `[data-word-id="${updatedPrevWords[updatedPrevWords.length - 1].id}"]`
            );
            if (lastWord) {
              moveCursorToNextWord(lastWord, 'forward');
            }
          }
  
          return newContent;
        }
        // We're at the first word of the first segment
        // Just return the content unchanged
        return content;
      }
    }
  
    // Regular word deletion within a segment
    const updatedWords = segment.words
      .filter((_, idx) => idx !== wordIndex)
      .map((w, idx) => ({ ...w, index: idx }));
  
    // Even if segment becomes empty, keep it if it's the first segment
    if (updatedWords.length === 0 && segmentIndex === 0) {
      const emptySegment = {
        ...segment,
        words: [],
        startTime: segment.startTime,
        endTime: segment.endTime
      };
      
      const newContent = [
        emptySegment,
        ...content.slice(1)
      ];
      
      return newContent;
    }
  
    // Handle empty segments for non-first segments
    if (updatedWords.length === 0) {
      const newContent = [
        ...content.slice(0, segmentIndex),
        ...content.slice(segmentIndex + 1)
      ];
      
      // Move cursor appropriately
      if (segmentIndex > 0) {
        const previousSegment = content[segmentIndex - 1];
        const lastWord = previousSegment.words[previousSegment.words.length - 1];
        if (lastWord) {
          const lastWordElement = document.querySelector(`[data-word-id="${lastWord.id}"]`);
          if (lastWordElement) {
            moveCursorToNextWord(lastWordElement, 'forward');
          }
        }
      }
      
      return newContent;
    }
  
    // Update segment with remaining words
    const newSegment = {
      ...segment,
      words: updatedWords,
      startTime: updatedWords[0]?.startTime || segment.startTime,
      endTime: updatedWords[updatedWords.length - 1]?.endTime || segment.endTime,
    };
  
    const newContent = [
      ...content.slice(0, segmentIndex),
      newSegment,
      ...content.slice(segmentIndex + 1)
    ];
  
    if (wordElement) {
      moveCursorToNextWord(wordElement, 'backward');
    }
  
    return newContent;
  };

  const splitSegmentAtCursor = useCallback((content) => {
    const selectedWord = findSelectedWord();
    if (!selectedWord) return content;
  
    const { segmentId, wordId } = selectedWord;
    const segmentIndex = content.findIndex(s => s.id === segmentId);
    if (segmentIndex === -1) return content;
  
    const segment = content[segmentIndex];
    const wordIndex = segment.words.findIndex(w => w.id === wordId);
    if (wordIndex === -1) return content;
  
    const splitIndex = wordIndex + 1;
  
    const firstHalfWords = segment.words.slice(0, splitIndex).map((word, idx) => ({
      ...word,
      id: uuidv4(),
      index: idx
    }));
  
    const secondHalfWords = segment.words.slice(splitIndex).map((word, idx) => ({
      ...word,
      id: uuidv4(),
      index: idx
    }));
  
    const firstHalf = {
      id: uuidv4(),
      speaker: segment.speaker,
      startTime: firstHalfWords[0]?.startTime,
      endTime: firstHalfWords[firstHalfWords.length - 1]?.endTime,
      words: firstHalfWords,
      sourceReference: {
        ...segment.sourceReference,
        index: segment.sourceReference.index
      }
    };
  
    if (secondHalfWords.length === 0) {
      return content;
    }
  
    const secondHalf = {
      id: uuidv4(),
      speaker: segment.speaker,
      startTime: secondHalfWords[0]?.startTime,
      endTime: secondHalfWords[secondHalfWords.length - 1]?.endTime,
      words: secondHalfWords,
      sourceReference: {
        ...segment.sourceReference,
        index: (segment.sourceReference.index || 0) + 1
      }
    };
  
    const newContent = [
      ...content.slice(0, segmentIndex),
      firstHalf,
      secondHalf,
      ...content.slice(segmentIndex + 1)
    ];
  
    requestAnimationFrame(() => {
      const firstWordElement = document.querySelector(`[data-word-id="${secondHalf.words[0].id}"]`);
      if (firstWordElement) {
        moveCursorToNextWord(firstWordElement, 'forward');
      }
    });
  
    return newContent;
  }, []); // Empty dependencies array since all dependencies are stable

  const addToPapercut = useCallback((papercutId, selectedContent) => {
    const transformedContent = selectedContent.map(transformSegment);
    const mergedContent = mergeSegmentsWithSameSpeaker(transformedContent);
    addContentToPapercut(papercutId, mergedContent);
  }, [addContentToPapercut, transformSegment, mergeSegmentsWithSameSpeaker]);

  // Modified insertToPapercut function with improved cursor handling
  const insertToPapercut = useCallback(async (papercutId, selectedContent, cursorPosition) => {
    let effectivePapercutId = papercutId;
    let effectivePapercut = papercuts.find(p => p.id === papercutId);

    if (!effectivePapercut) {
      const newPapercutId = await createNewPapercut();
      if (!newPapercutId) return;

      setActiveTab(Number(newPapercutId));
      await fetchFiles();

      effectivePapercutId = newPapercutId;
      effectivePapercut = papercuts.find(p => p.id === newPapercutId);
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    const effectiveCursorPosition = cursorPosition || lastCursorPosition;
    const content = effectivePapercut?.content || [];

    if (!Array.isArray(selectedContent) || selectedContent.length === 0) {
      return null;
    }

    // Transform and merge the new content
    const transformedContent = selectedContent.map((segment, index) =>
      transformSegment(segment, index)
    );
    const mergedInsertContent = mergeSegmentsWithSameSpeaker(transformedContent);

    // === CASE: No cursor or empty content
    if (!effectiveCursorPosition || content.length === 0) {
      const newContent = [...content, ...mergedInsertContent];
      updatePapercutContent(effectivePapercutId, newContent);

      const lastSegment = mergedInsertContent[mergedInsertContent.length - 1];
      const lastWord = lastSegment?.words[lastSegment.words.length - 1];
      if (lastWord) {
        updateCursorPosition({
          segmentId: lastSegment.id,
          wordId: lastWord.id,
          offset: lastWord.text.length
        });
        scrollToWord(lastWord.id); // SCROLL ADDED
      }
      return lastSegment.id;
    }

    // === CASE: We have a cursor position
    const { segmentId, wordId, offset = 0 } = effectiveCursorPosition;
    const segmentIndex = content.findIndex(s => s.id === segmentId);

    // If cursor segment not found, append at end
    if (segmentIndex === -1) {
      const newContent = [...content, ...mergedInsertContent];
      updatePapercutContent(effectivePapercutId, newContent);

      const lastSegment = mergedInsertContent[mergedInsertContent.length - 1];
      const lastWord = lastSegment?.words[lastSegment.words.length - 1];
      if (lastWord) {
        updateCursorPosition({
          segmentId: lastSegment.id,
          wordId: lastWord.id,
          offset: lastWord.text.length
        });
        scrollToWord(lastWord.id); // SCROLL ADDED
      }
      return lastSegment.id;
    }

    const segment = content[segmentIndex];
    const wordIndex = segment.words.findIndex(w => w.id === wordId);

    // If cursor word not found, insert after segment
    if (wordIndex === -1) {
      const newContent = [
        ...content.slice(0, segmentIndex + 1),
        ...mergedInsertContent,
        ...content.slice(segmentIndex + 1)
      ];
      updatePapercutContent(effectivePapercutId, newContent);

      const lastSegment = mergedInsertContent[mergedInsertContent.length - 1];
      const lastWord = lastSegment?.words[lastSegment.words.length - 1];
      if (lastWord) {
        updateCursorPosition({
          segmentId: lastSegment.id,
          wordId: lastWord.id,
          offset: lastWord.text.length
        });
        scrollToWord(lastWord.id); // SCROLL ADDED
      }
      return lastSegment.id;
    }

    const word = segment.words[wordIndex];
    const isAtEndOfWord = offset === word.text.length;
    const shouldInsertAfterCurrentWord = isAtEndOfWord;

    // === CASE: Insert BEFORE current word
    if (!shouldInsertAfterCurrentWord) {
      const firstHalf = {
        ...segment,
        id: uuidv4(),
        words: segment.words.slice(0, wordIndex).map((w, idx) => ({
          ...w,
          id: uuidv4(),
          index: idx
        })),
        endTime: segment.words[wordIndex - 1]?.endTime || segment.startTime
      };

      const secondHalf = {
        ...segment,
        id: uuidv4(),
        words: segment.words.slice(wordIndex).map((w, idx) => ({
          ...w,
          id: uuidv4(),
          index: idx
        })),
        startTime: word.startTime
      };

      const segmentsToMerge = [
        ...(firstHalf.words.length > 0 ? [firstHalf] : []),
        ...mergedInsertContent,
        secondHalf
      ];

      const mergedSegments = mergeSegmentsWithSameSpeaker(segmentsToMerge);
      const newContent = [
        ...content.slice(0, segmentIndex),
        ...mergedSegments,
        ...content.slice(segmentIndex + 1)
      ];

      updatePapercutContent(effectivePapercutId, newContent);

      const lastMergedSegment = mergedSegments[mergedSegments.length - 1];
      const lastWord = lastMergedSegment.words[lastMergedSegment.words.length - 1];
      if (lastWord) {
        updateCursorPosition({
          segmentId: lastMergedSegment.id,
          wordId: lastWord.id,
          offset: lastWord.text.length
        });
        scrollToWord(lastWord.id); // SCROLL ADDED
      }
    }
    // === CASE: Insert AFTER current word
    else {
      const firstHalf = {
        ...segment,
        id: uuidv4(),
        words: segment.words.slice(0, wordIndex + 1).map((w, idx) => ({
          ...w,
          id: uuidv4(),
          index: idx
        })),
        endTime: word.endTime
      };

      const secondHalf = {
        ...segment,
        id: uuidv4(),
        words: segment.words.slice(wordIndex + 1).map((w, idx) => ({
          ...w,
          id: uuidv4(),
          index: idx
        })),
        startTime: segment.words[wordIndex + 1]?.startTime || word.endTime
      };

      const segmentsToMerge = [
        firstHalf,
        ...mergedInsertContent,
        ...(secondHalf.words.length > 0 ? [secondHalf] : [])
      ];

      const mergedSegments = mergeSegmentsWithSameSpeaker(segmentsToMerge);
      const newContent = [
        ...content.slice(0, segmentIndex),
        ...mergedSegments,
        ...content.slice(segmentIndex + 1)
      ];

      updatePapercutContent(effectivePapercutId, newContent);

      const lastMergedSegment = mergedSegments[mergedSegments.length - 1];
      const lastWord = lastMergedSegment.words[lastMergedSegment.words.length - 1];
      if (lastWord) {
        updateCursorPosition({
          segmentId: lastMergedSegment.id,
          wordId: lastWord.id,
          offset: lastWord.text.length
        });
        scrollToWord(lastWord.id); // SCROLL ADDED
      }
    }
  }, [
    papercuts,
    lastCursorPosition,
    createNewPapercut,
    setActiveTab,
    fetchFiles,
    transformSegment,
    mergeSegmentsWithSameSpeaker,
    updatePapercutContent,
    updateCursorPosition
  ]);

  const insertFromLLM = useCallback((papercutId, llmSegments) => {
    if (!Array.isArray(llmSegments) || llmSegments.length === 0) {
      console.warn('No segments returned from LLM to insert.');
      return;
    }
    insertToPapercut(papercutId, llmSegments);
  }, [insertToPapercut]);

  return {
    splitSegmentAtCursor,
    deleteWordAtCursor,
    addToPapercut,
    insertToPapercut,
    insertFromLLM,
    transformSegment
  };
}