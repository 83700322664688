/**
 * Returns contextual info about the current cursor position within a word.
 * @returns {null | {
*   segmentId: string,
*   wordId: string,
*   anchorOffset: number,  // caret offset within the text node
*   textLength: number,    // total length of the text node
* }}
*/
function getCursorWordContext() {
 const sel = window.getSelection();
 if (!sel || !sel.rangeCount) return null;

 let { anchorNode, anchorOffset } = sel;
 if (!anchorNode) return null;

 // If we're in an element node, try to move into its text node child
 if (anchorNode.nodeType !== Node.TEXT_NODE) {
   const textChild = Array.from(anchorNode.childNodes).find(
     (node) => node.nodeType === Node.TEXT_NODE
   );
   if (!textChild) return null;
   anchorNode = textChild;
   anchorOffset = 0; // Because we moved to start of that text node
 }

 // Find the nearest word element
 const wordElement = anchorNode.parentElement?.closest('[data-word-id]');
 if (!wordElement) return null;

 const wordId = wordElement.getAttribute('data-word-id');
 const segmentElement = wordElement.closest('[data-segment-id]');
 if (!segmentElement) return null;

 const segmentId = segmentElement.getAttribute('data-segment-id');
 const textLength = anchorNode.nodeValue?.length ?? 0;

 return {
   segmentId,
   wordId,
   anchorOffset,
   textLength
 };
}

/**
* Find the segment and word index in your content structure.
*/
function findWordIndexInSegment(content, segmentId, wordId) {
 const segIndex = content.findIndex((s) => s.id === segmentId);
 if (segIndex < 0) return null;

 const wIndex = content[segIndex].words.findIndex((w) => w.id === wordId);
 if (wIndex < 0) return null;

 return { segIndex, wIndex };
}

/**
* Returns the "previous" word's ID (in the same or previous segment).
*/
function getPreviousWordId(content, segmentId, wordId) {
 const indices = findWordIndexInSegment(content, segmentId, wordId);
 if (!indices) return null;

 const { segIndex, wIndex } = indices;
 if (wIndex > 0) {
   return content[segIndex].words[wIndex - 1]?.id;
 }
 // Optionally jump to the last word of the previous segment
 if (segIndex > 0) {
   const prevSegment = content[segIndex - 1];
   return prevSegment.words[prevSegment.words.length - 1]?.id;
 }
 return null;
}

/**
* Returns the "next" word's ID (in the same or next segment).
*/
function getNextWordId(content, segmentId, wordId) {
 const indices = findWordIndexInSegment(content, segmentId, wordId);
 if (!indices) return null;

 const { segIndex, wIndex } = indices;
 const segment = content[segIndex];

 // Next word in the current segment
 if (wIndex < segment.words.length - 1) {
   return segment.words[wIndex + 1]?.id;
 }
 // Or first word of the next segment
 if (segIndex < content.length - 1) {
   const nextSegment = content[segIndex + 1];
   return nextSegment.words[0]?.id;
 }
 return null;
}

/**
* Delete a single word by ID.
* (You might already have this or something similar.)
*/
function deleteWordById(content, targetWordId) {
 return content
   .map((segment) => {
     const newWords = segment.words
       .filter((w) => w.id !== targetWordId)
       .map((w, idx) => ({ ...w, index: idx }));
     return {
       ...segment,
       words: newWords
     };
   })
   // Optionally remove any empty segments
   .filter((seg) => seg.words.length > 0);
}

/**
* Split the segment after a given word.
* (You may have your own existing logic for segment splitting.)
*/
function splitSegmentAfterWordId(content, segmentId, wordId) {
 const indices = findWordIndexInSegment(content, segmentId, wordId);
 if (!indices) return content; // nothing to split
 const { segIndex, wIndex } = indices;

 const segment = content[segIndex];
 const firstHalf = segment.words.slice(0, wIndex + 1).map((word, idx) => ({
   ...word,
   index: idx
 }));
 const secondHalf = segment.words.slice(wIndex + 1).map((word, idx) => ({
   ...word,
   index: idx
 }));

 // Create new segments
 const firstSegment = {
   ...segment,
   words: firstHalf
 };
 const newSegment = {
   ...segment,
   id: `split-${Date.now()}`, // or your own ID generation
   words: secondHalf
 };

 return [
   ...content.slice(0, segIndex),
   firstSegment,
   ...(secondHalf.length ? [newSegment] : []), // only add if not empty
   ...content.slice(segIndex + 1)
 ];
}

// -----------------------------------------
// Export each helper so you can import them
// -----------------------------------------
export {
 getCursorWordContext,
 findWordIndexInSegment,
 getPreviousWordId,
 getNextWordId,
 deleteWordById,
 splitSegmentAfterWordId
};
