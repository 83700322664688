import React, { createContext, useContext, useEffect, useState } from 'react';
import { usePapercutSyncHook } from '../hooks/usePapercut/usePapercutSync';
import { useFileSystem } from '../contexts/FileSystemContext';

const PapercutContext = createContext();

export function PapercutProvider({ children }) {
  const {
    papercuts,
    activeTab,
    setActiveTab,
    cursorPosition,
    updateCursorPosition,
    updatePapercutContent,
    addContentToPapercut,
    insertContentToPapercut,
    createNewPapercut: createNewPapercutOriginal
  } = usePapercutSyncHook();

  const { activePapercutId, setActivePapercutId } = useFileSystem();
  const [lastCursorPosition, setLastCursorPosition] = useState(null);

  // Update lastCursorPosition when cursorPosition changes
  useEffect(() => {
    if (cursorPosition) {
      setLastCursorPosition(cursorPosition);
    }
  }, [cursorPosition]);

  // Clear cursor when active papercut changes
  useEffect(() => {
    if (!activeTab) {
      setLastCursorPosition(null);
    }
  }, [activeTab]);

  // Enhanced createNewPapercut that syncs both contexts
  const createNewPapercut = async () => {
    const newPapercutId = await createNewPapercutOriginal();
    if (newPapercutId) {
      setActivePapercutId(newPapercutId);
      setActiveTab(Number(newPapercutId));
    }
    return newPapercutId;
  };

  // Sync active papercut changes from FileSystem
  useEffect(() => {
    if (activePapercutId && activeTab !== Number(activePapercutId)) {
      setActiveTab(Number(activePapercutId));
    } else if (!activePapercutId && activeTab) {
      setActiveTab(null);
    }
  }, [activePapercutId, activeTab, setActiveTab]);

  return (
    <PapercutContext.Provider value={{
      papercuts,
      activeTab,
      setActiveTab,
      cursorPosition,
      updateCursorPosition,
      updatePapercutContent,
      addContentToPapercut,
      insertContentToPapercut,
      createNewPapercut,
      lastCursorPosition,
      setLastCursorPosition
    }}>
      {children}
    </PapercutContext.Provider>
  );
}

export const usePapercuts = () => {
  const context = useContext(PapercutContext);
  if (!context) {
    throw new Error('usePapercuts must be used within PapercutProvider');
  }
  return context;
};
