import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext(null);

const DEFAULT_CREDENTIALS = {
  email: '123@chatcut.io',
  password: '123'
};
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [token, setToken] = useState(null);

  useEffect(() => {
    console.log('[AuthProvider] useEffect: Checking localStorage for user/token');
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    console.log('[AuthProvider] storedUser:', storedUser, 'storedToken:', storedToken);
    if (storedUser && storedToken) {
      console.log('[AuthProvider] User and token found, setting isAuthenticated to true');
      setUser(JSON.parse(storedUser));
      setToken(storedToken);
      setIsAuthenticated(true);
    } else if (storedUser && !storedToken) {
      console.log('[AuthProvider] User found without token (fallback login), setting isAuthenticated to true');
      setUser(JSON.parse(storedUser));
      // Fallback login - no token
      setIsAuthenticated(true);
    } else {
      console.log('[AuthProvider] No user/token found');
    }
  }, []);

  const login = async (credentials) => {
    console.log('[AuthProvider] login called with credentials:', credentials);
    setError('');

    if (credentials.email === DEFAULT_CREDENTIALS.email && 
        credentials.password === DEFAULT_CREDENTIALS.password) {
      console.log('[AuthProvider] Using default fallback credentials');
      const userData = { email: credentials.email };
      setUser(userData);
      setIsAuthenticated(true);
      localStorage.setItem('user', JSON.stringify(userData));
      // No token for fallback login
      setToken(null);
      return true;
    }

    console.log('[AuthProvider] Attempting DB login via AuthContext');
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials)
      });

      console.log('[AuthProvider] DB login attempt response status:', res.status);
      if (!res.ok) {
        const errData = await res.json().catch(() => ({}));
        console.log('[AuthProvider] DB login failed response data:', errData);
        throw new Error(errData.error || 'Failed to login via DB');
      }

      const data = await res.json();
      console.log('[AuthProvider] DB login succeeded, data:', data);
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));

      setUser(data.user);
      setToken(data.token);
      setIsAuthenticated(true);
      return true;
    } catch (err) {
      console.error('[AuthProvider] DB login failed:', err.message);
      setError(err.message);
      return false;
    }
  };

  const logout = () => {
    console.log('[AuthProvider] logout called');
    setUser(null);
    setToken(null);
    setIsAuthenticated(false);
    setError('');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, token, error, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => useContext(AuthContext);
