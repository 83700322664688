import { useCallback, useContext } from 'react';
import { ClipboardContext } from '../../contexts/ClipboardContext';
import { getCursorWordContext, splitSegmentAfterWordId } from '../../utils/papercutUtils/cursorWordUtils';

export function usePapercutKeyboard({
  content,
  selection,
  handlers,
  operations
}) {
  const { storeInClipboard } = useContext(ClipboardContext);
  const { getSelectedContent, clearSelection } = handlers;
  const {
    undo,
    redo,
    canUndo,
    canRedo,
    handleContentUpdate,
    deleteWordAtCursor
  } = operations;

  const handleSelectionDeletion = useCallback((selectedContent) => {
    if (!selectedContent || selectedContent.length === 0) return content;
    
    // Create maps for quick lookup
    const segmentMap = new Map();
    const wordMap = new Map();
    
    selectedContent.forEach(segment => {
      segmentMap.set(segment.id, segment);
      segment.words.forEach(word => {
        wordMap.set(word.id, true);
      });
    });
    
    // Helper function to map words with proper indexing
    const mapWordsWithIndex = (words, startIndex = 0) => 
      words.map((word, idx) => ({ ...word, index: startIndex + idx }));
    
    // Process segments and handle merging
    let newContent = [];
    let pendingSegment = null;
  
    for (let i = 0; i < content.length; i++) {
      const segment = content[i];
      const isSegmentSelected = segmentMap.has(segment.id);
      
      // Filter out selected words
      const remainingWords = segment.words
        .filter(word => !wordMap.has(word.id));
      
      // Skip entirely selected empty segments
      if (remainingWords.length === 0 && isSegmentSelected) {
        continue;
      }
      
      // Process remaining words
      if (remainingWords.length > 0) {
        const currentSegment = {
          ...segment,
          words: mapWordsWithIndex(remainingWords),
          startTime: remainingWords[0].startTime,
          endTime: remainingWords[remainingWords.length - 1].endTime
        };
  
        // Try to merge with pending segment
        if (pendingSegment && pendingSegment.speaker === currentSegment.speaker) {
          const mergedWords = [
            ...pendingSegment.words,
            ...mapWordsWithIndex(currentSegment.words, pendingSegment.words.length)
          ];
          
          pendingSegment = {
            ...pendingSegment,
            endTime: currentSegment.endTime,
            words: mergedWords
          };
        } else {
          if (pendingSegment) {
            newContent.push(pendingSegment);
          }
          pendingSegment = currentSegment;
        }
      }
    }
  
    // Add final pending segment
    if (pendingSegment) {
      newContent.push(pendingSegment);
    }
  
    return newContent;
  }, [content]);

  const handleKeyDown = useCallback(
    (event) => {
      const key = event.key;

      switch (key) {
        // === BACKSPACE ===
        case 'Backspace': {
          // Handle selection deletion
          if (selection && getSelectedContent().length > 0) {
            event.preventDefault();
            const selectedContent = getSelectedContent();
            const newContent = handleSelectionDeletion(selectedContent);
            handleContentUpdate(newContent);
            clearSelection();
            return;
          }

          const ctx = getCursorWordContext();
          if (!ctx) break;

          const { segmentId, wordId, anchorOffset } = ctx;
          const segmentIndex = content.findIndex(s => s.id === segmentId);
          
          if (anchorOffset === 0 && segmentIndex > 0) {
            const wordElement = document.querySelector(`[data-word-id="${wordId}"]`);
            const isFirstWord = wordElement && !wordElement.previousElementSibling;
            
            if (isFirstWord) {
              const currentSegment = content[segmentIndex];
              const previousSegment = content[segmentIndex - 1];

              if (previousSegment && previousSegment.speaker !== currentSegment.speaker) {
                event.preventDefault();
                deleteWordAtCursor();
                return;
              }
            }
          }

          event.preventDefault();
          deleteWordAtCursor();
          break;
        }

        // === DELETE ===
        case 'Delete': {
          // Handle selection deletion
          if (selection && getSelectedContent().length > 0) {
            event.preventDefault();
            const selectedContent = getSelectedContent();
            const newContent = handleSelectionDeletion(selectedContent);
            handleContentUpdate(newContent);
            clearSelection();
            return;
          }

          const ctx = getCursorWordContext();
          if (!ctx) break;

          event.preventDefault();
          deleteWordAtCursor();
          break;
        }

        // === ENTER ===
        case 'Enter': {
          const ctx = getCursorWordContext();
          if (!ctx) break;

          event.preventDefault();
          const { segmentId, wordId } = ctx;
          const newContent = splitSegmentAfterWordId(content, segmentId, wordId);
          handleContentUpdate(newContent);
          break;
        }

        // === COPY (Ctrl/Cmd + C) ===
        case 'c': {
          if ((event.ctrlKey || event.metaKey) && selection) {
            event.preventDefault();
            const selectedContent = getSelectedContent();
            if (selectedContent?.length > 0) {
              storeInClipboard(selectedContent, 'papercut');
            }
          }
          break;
        }

           // === CUT (Ctrl/Cmd + X) ===
           case 'x': {
            if ((event.ctrlKey || event.metaKey) && selection) {
              event.preventDefault();
              const selectedContent = getSelectedContent();
              if (selectedContent?.length > 0) {
                storeInClipboard(selectedContent, 'papercut');
                const newContent = handleSelectionDeletion(selectedContent);
                handleContentUpdate(newContent);
                clearSelection();
              }
            }
            break;
          }

        // === UNDO/REDO (Ctrl/Cmd + Z) ===
        case 'z': {
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
            if (event.shiftKey && canRedo) {
              redo();
            } else if (canUndo) {
              undo();
            }
          }
          break;
        }

        default:
          break;
      }
    },
    [
      content,
      selection,
      getSelectedContent,
      clearSelection,
      handleContentUpdate,
      deleteWordAtCursor,
      storeInClipboard,
      canUndo,
      canRedo,
      undo,
      redo,
      handleSelectionDeletion
    ]
  );

  return handleKeyDown;
}