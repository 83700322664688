/**
 * Find the current word from selection
 * @returns {object|null} Current word information
 */
/**
 * Find the current word at cursor/selection
 * @returns {object|null} Word information
 */
const findSelectedWord = () => {
  try {
    const sel = window.getSelection();
    if (!sel || !sel.rangeCount) return null;

    let node = sel.anchorNode;
    if (!node) return null;

    // Handle text node selection
    if (node.nodeType === Node.TEXT_NODE) {
      node = node.parentElement;
    }

    // Find word element
    while (node && !node.hasAttribute('data-word-id')) {
      node = node.parentElement;
      if (!node || node === document.body) return null;
    }

    if (!node?.hasAttribute('data-word-id')) return null;

    // Find segment element
    const segmentElement = node.closest('[data-segment-id]');
    if (!segmentElement) return null;

    return {
      wordId: node.getAttribute('data-word-id'),
      segmentId: segmentElement.getAttribute('data-segment-id')
    };
  } catch (error) {
    console.error('Error finding selected word:', error);
    return null;
  }
};

/**
 * Move cursor to next word after deletion/split
 * @param {Element} wordElement - Current word element
 * @param {string} direction - 'forward' or 'backward'
 */

const moveCursorToNextWord = (wordElement, direction = 'forward') => {
  if (!wordElement) return;

  let nextWord;
  if (direction === 'forward') {
    nextWord = wordElement.nextElementSibling;
    while (!nextWord?.hasAttribute('data-word-id') && wordElement.parentElement) {
      const nextSegment = wordElement.closest('[data-segment-id]')?.nextElementSibling;
      if (!nextSegment) break;
      nextWord = nextSegment.querySelector('[data-word-id]');
    }
  } else {
    nextWord = wordElement.previousElementSibling;
    while (!nextWord?.hasAttribute('data-word-id') && wordElement.parentElement) {
      const prevSegment = wordElement.closest('[data-segment-id]')?.previousElementSibling;
      if (!prevSegment) break;
      const words = prevSegment.querySelectorAll('[data-word-id]');
      nextWord = words[words.length - 1];
    }
  }

  if (nextWord) {
    // Get first text node
    const textNode = Array.from(nextWord.childNodes)
      .find(node => node.nodeType === Node.TEXT_NODE);
    
    if (textNode) {
      const range = document.createRange();
      if (direction === 'forward') {
        range.setStart(textNode, 0);
      } else {
        range.setStart(textNode, textNode.length);
      }
      range.collapse(true);
      
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      nextWord.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }
};

/**
 * Find the word closest to a point
 * @param {Element} container - Container element
 * @param {number} x - X coordinate
 * @param {number} y - Y coordinate
 * @returns {Element|null} Closest word element
 */
const findClosestWord = (container, x, y) => {
  const words = Array.from(container.querySelectorAll('[data-word-id]'));
  let closest = null;
  let minDistance = Infinity;

  words.forEach(word => {
    const rect = word.getBoundingClientRect();
    const wordX = rect.left + rect.width / 2;
    const wordY = rect.top + rect.height / 2;
    const distance = Math.sqrt(
      Math.pow(x - wordX, 2) + 
      Math.pow(y - wordY, 2)
    );

    if (distance < minDistance) {
      minDistance = distance;
      closest = word;
    }
  });

  return closest;
};

/**
 * Get all selected words between cursor positions
 * @returns {Array} Array of selected word elements
 */
const getSelectedWords = () => {
  const sel = window.getSelection();
  if (!sel.rangeCount) return [];

  const range = sel.getRangeAt(0);
  if (range.collapsed) return [];

  // Get start and end containers
  let startContainer = range.startContainer;
  let endContainer = range.endContainer;

  // If we're in text nodes, get their parents
  if (startContainer.nodeType === Node.TEXT_NODE) {
    startContainer = startContainer.parentElement;
  }
  if (endContainer.nodeType === Node.TEXT_NODE) {
    endContainer = endContainer.parentElement;
  }

  // Find all word elements in between
  const words = document.querySelectorAll('[data-word-id]');
  const selectedWords = [];
  let isInSelection = false;

  for (const word of words) {
    if (word === startContainer || word.contains(startContainer)) {
      isInSelection = true;
    }

    if (isInSelection) {
      selectedWords.push(word);
    }

    if (word === endContainer || word.contains(endContainer)) {
      break;
    }
  }

  return selectedWords;
};

/**
 * Get the next cursor position given content, direction, and current position.
 * @param {Array} content - Array of segments, each with an `id` and `words` array
 * @param {string} direction - 'left', 'right', 'up', or 'down'
 * @param {object} cursorPosition - { segmentId, wordId, ... }
 * @returns {object|null} The new cursor position or null if none found
 */
function getNextCursorPosition(content, direction, cursorPosition) {
  // Find the current segment
  const segmentIndex = content.findIndex(
    (segment) => segment.id === cursorPosition.segmentId
  );
  if (segmentIndex < 0) return null;

  const currentSegment = content[segmentIndex];
  // Find the current word index
  const wordIndex = currentSegment.words.findIndex(
    (w) => w.id === cursorPosition.wordId
  );
  if (wordIndex < 0) return null;

  // Example: handle 'left' and 'right' only. 
  // For 'up'/'down', you'd implement your own logic.
  if (direction === 'left') {
    // Move to previous word in this segment if it exists
    if (wordIndex > 0) {
      return {
        segmentId: currentSegment.id,
        wordId: currentSegment.words[wordIndex - 1].id,
        isStartOfWord: false,
        hasNextSegment: segmentIndex < content.length - 1
      };
    }

    // Otherwise, jump to the last word of the previous segment
    if (segmentIndex > 0) {
      const prevSegment = content[segmentIndex - 1];
      const lastWord = prevSegment.words[prevSegment.words.length - 1];
      return {
        segmentId: prevSegment.id,
        wordId: lastWord.id,
        isStartOfWord: false,
        hasNextSegment: segmentIndex - 1 < content.length - 1
      };
    }
    // No previous word or segment
    return null;
  }

  if (direction === 'right') {
    // Move to next word in this segment if it exists
    if (wordIndex < currentSegment.words.length - 1) {
      return {
        segmentId: currentSegment.id,
        wordId: currentSegment.words[wordIndex + 1].id,
        isStartOfWord: true,
        hasNextSegment: segmentIndex < content.length - 1
      };
    }

    // Otherwise, jump to the first word of the next segment
    if (segmentIndex < content.length - 1) {
      const nextSegment = content[segmentIndex + 1];
      const firstWord = nextSegment.words[0];
      return {
        segmentId: nextSegment.id,
        wordId: firstWord.id,
        isStartOfWord: true,
        hasNextSegment: segmentIndex + 1 < content.length - 1
      };
    }
    // No next word or segment
    return null;
  }

  // If you want to handle 'up' / 'down' or any other directions, add logic here.
  return null;
}

export {
  findSelectedWord,
  moveCursorToNextWord,
  findClosestWord,
  getSelectedWords,
  getNextCursorPosition
};