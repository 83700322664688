// src/components/Chatbot/Api.js
import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL || 'http://74.235.95.232:8004';
export const AKASH_API_URL = 'https://chatapi.akash.network/api/v1/chat/completions';
export const AKASH_API_KEY = 'sk-PfeZjCAKproWYwMQfw2biw';

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Export a helper function to set the token
export function setAuthToken(token) {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
}

// Filter function remains the same
function filterWordTimings(response) {
  try {
    let cleanedResponse = response
      .replace(/`/g, '') 
      .replace(/\*\*/g, '');

    const wordTimingRegex = /([^|\s]+)\|(\d+(?:\.\d+)?)\|(\d+(?:\.\d+)?)\|SPEAKER_\d+/g;
    const matches = [...cleanedResponse.matchAll(wordTimingRegex)];

    if (matches.length === 0) {
      console.error('No matches found in response:', response);
      throw new Error('No valid word timings found in response');
    }

    const result = matches.map(match => match[0]).join(' ');
    console.log('Found word timings:', matches.length);
    console.log('First few matches:', matches.slice(0, 3));

    return result;
  } catch (error) {
    console.error('Error filtering word timings:', error);
    throw error;
  }
}

export const sendToLlama = async (wordTimingJson, promptTemplate, userInput, task) => {
  try {
    const strictPrompt = `
Edit the provided transcript according to this request: ${userInput}
CRITICAL: Your response must ONLY contain word timings in exactly this format:
word|number|number|SPEAKER_XX
Example format:
voice|8.441|8.721|SPEAKER_01
listen|7.66|7.88|SPEAKER_01
DO NOT include any text, markdown, formatting, or explanation.
DO NOT use backticks or any other characters.
Just the word timings, one per line.

Original Transcript JSON: ${wordTimingJson}`;

    const response = await axios.post(
      AKASH_API_URL,
      {
        model: 'nvidia-Llama-3-1-Nemotron-70B-Instruct-HF',
        messages: [
          {
            role: 'user',
            content: strictPrompt
          }
        ]
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${AKASH_API_KEY}`
        }
      }
    );

    if (response.data?.choices?.[0]?.message?.content) {
      console.log('Raw response:', response.data.choices[0].message.content.slice(0, 200) + '...');
      const filteredResponse = filterWordTimings(response.data.choices[0].message.content);

      if (!filteredResponse || filteredResponse.trim().length === 0) {
        throw new Error('Filtering produced empty result');
      }
      
      return filteredResponse;
    } else {
      throw new Error('Invalid response format from Llama API');
    }
  } catch (error) {
    console.error('Error processing Llama request:', error);
    if (error.response) {
      console.error('Llama API responded with error:', error.response.data);
      throw new Error(error.response.data.error || 'Llama API server error');
    } else if (error.request) {
      console.error('No response received from Llama API');
      throw new Error('No response from Llama API server');
    } else {
      throw error;
    }
  }
};

export const sendToLLM = async (wordTimingJson, promptTemplate, userInput, task) => {
  const payload = {
    text: wordTimingJson,
    prompt_template: promptTemplate,
    user_input: userInput,
    task: task,
  };
  console.log('Sending to LLM:', payload);

  try {
    // Using the axios instance so headers (including Authorization) are applied
    const response = await instance.post('/api/llm/process', payload);
    // Assuming the server always returns { "result": [...] }
    console.log('Server Response in SendToLLM:', response.data.result);
    return response.data.result;
  } catch (error) {
    console.error('Error processing LLM request:', error);
    if (error.response) {
      console.error('LLM server responded with error:', error.response.data);
      throw new Error(error.response.data.error || 'LLM server error');
    } else if (error.request) {
      console.error('No response from LLM server');
      throw new Error('No response from LLM server');
    } else {
      throw error;
    }
  }
};

