import { useState, useCallback, useEffect } from 'react';

/**
 * Hook to manage selection and cursor position in a Papercut environment using native browser selection.
 *
 * @param {Array} papercutContent - The papercut content array:
 * [
 *   {
 *     id: 'segment-1',
 *     speaker: 'Speaker Name',
 *     words: [
 *       { id: 'word-1', text: 'Hello', startTime: 0, endTime: 1 },
 *       { id: 'word-2', text: 'world', startTime: 1, endTime: 2 }
 *     ]
 *   },
 *   ...
 * ]
 */
export const usePapercutSelection = (papercutContent) => {
  const [selection, setSelection] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);

  const isValidPapercutNode = useCallback((node) => {
    if (!node || !node.dataset) return false;
    const { segmentId, wordId } = node.dataset;
    return !!(segmentId && wordId);
  }, []);

  const findClosestWordNode = useCallback((node) => {
    if (!node) return null;
    if (node.nodeType === Node.TEXT_NODE) node = node.parentElement;
    return node.closest('[data-segment-id][data-word-id]');
  }, []);

  const findValidNodesInRange = useCallback((range) => {
    if (!range || !range.commonAncestorContainer) {
      console.warn('Invalid range provided to findValidNodesInRange');
      return [];
    }

    const container = range.commonAncestorContainer.nodeType === Node.TEXT_NODE
      ? range.commonAncestorContainer.parentElement
      : range.commonAncestorContainer;

    // If container itself is valid:
    if (isValidPapercutNode(container)) {
      return [container];
    }

    const nodes = [];
    const walker = document.createTreeWalker(
      range.commonAncestorContainer,
      NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT,
      {
        acceptNode: (node) => {
          const elementToCheck = node.nodeType === Node.TEXT_NODE ? node.parentElement : node;
          return isValidPapercutNode(elementToCheck) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
        }
      }
    );

    let currentNode = walker.currentNode;
    while (currentNode) {
      const elementNode = currentNode.nodeType === Node.TEXT_NODE ? currentNode.parentElement : currentNode;
      if (range.intersectsNode(currentNode) && isValidPapercutNode(elementNode)) {
        nodes.push(elementNode);
      }
      currentNode = walker.nextNode();
    }

    return nodes;
  }, [isValidPapercutNode]);

  const updateSelectionState = useCallback(() => {
    const sel = window.getSelection();
    if (!sel || sel.rangeCount === 0) {
      setSelection(null);
      setIsActive(false);
      setCursorPosition(null);
      return;
    }

    const range = sel.getRangeAt(0);
    if (!range) {
      setSelection(null);
      setIsActive(false);
      setCursorPosition(null);
      return;
    }

    const selectedText = range.toString();

    if (!selectedText) {
      // Collapsed selection => caret position
      // Find the node at the caret
      const caretNode = range.startContainer;
      const wordElement = findClosestWordNode(caretNode);
      if (wordElement) {
        const { segmentId, wordId } = wordElement.dataset;
        // Here you could decide if the caret is at start or end of the word by checking range.startOffset
        // For simplicity, we’ll just return the word boundary as is.
        setCursorPosition({ segmentId, wordId });
      } else {
        // Not inside a valid papercut node
        setCursorPosition(null);
      }
      setSelection(null);
      setIsActive(false);
    } else {
      // There's a selected text range
      const validNodes = findValidNodesInRange(range);
      if (validNodes.length === 0) {
        setSelection(null);
        setIsActive(false);
        setCursorPosition(null);
        return;
      }

      const startElement = validNodes[0];
      const endElement = validNodes[validNodes.length - 1];

      if (!isValidPapercutNode(startElement)) {
        setSelection(null);
        setIsActive(false);
        setCursorPosition(null);
        return;
      }

      const newSelection = {
        text: selectedText.trim(),
        start: {
          segmentId: startElement.dataset.segmentId,
          wordId: startElement.dataset.wordId,
        },
        end: validNodes.length === 1 ? {
          segmentId: startElement.dataset.segmentId,
          wordId: startElement.dataset.wordId,
        } : {
          segmentId: endElement.dataset.segmentId,
          wordId: endElement.dataset.wordId,
        }
      };

      setSelection(newSelection);
      setIsActive(true);
      // In case of a selection (non-collapsed), no single cursor position
      setCursorPosition(null);
    }
  }, [findClosestWordNode, findValidNodesInRange, isValidPapercutNode]);

  const getSelectedContent = useCallback(() => {
    if (!selection) return [];

    const { start, end } = selection;
    if (!start || !end) {
      console.warn('Invalid selection boundaries:', selection);
      return [];
    }

    const selectedSegments = [];
    let selectionStarted = false;
    let selectionEnded = false;

    for (const segment of papercutContent) {
      if (selectionEnded) break;

      const segmentMatchesStart = segment.id === start.segmentId;
      const segmentMatchesEnd = segment.id === end.segmentId;

      const segmentWords = [];
      for (const word of segment.words) {
        if (!selectionStarted) {
          if (segmentMatchesStart && word.id === start.wordId) {
            selectionStarted = true;
            segmentWords.push(word);
            if (segmentMatchesEnd && word.id === end.wordId) {
              selectionEnded = true;
              break;
            }
          }
        } else {
          segmentWords.push(word);
          if (segmentMatchesEnd && word.id === end.wordId) {
            selectionEnded = true;
            break;
          }
        }
      }

      if (segmentWords.length > 0) {
        selectedSegments.push({
          segmentId: segment.id,
          speaker: segment.speaker,
          words: segmentWords,
          startTime: segmentWords[0]?.startTime ?? null,
          endTime: segmentWords[segmentWords.length - 1]?.endTime ?? null
        });
      }
    }

    return selectedSegments;
  }, [selection, papercutContent]);

  const clearSelection = useCallback(() => {
    window.getSelection().removeAllRanges();
    setSelection(null);
    setIsActive(false);
    setCursorPosition(null);
  }, []);

  useEffect(() => {
    document.addEventListener('selectionchange', updateSelectionState);
    return () => {
      document.removeEventListener('selectionchange', updateSelectionState);
    };
  }, [updateSelectionState]);

  return {
    selection,
    isActive,
    cursorPosition,
    clearSelection,
    getSelectedContent,
  };
};
