import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Paper,
  Divider,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  CircularProgress
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import PaperCutLogo from '../../images/login/PaperCut_Login_banner.png';

const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#555555',
    },
    action: {
      hover: '#f0f0f0',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const LoginPage = () => {
  const { login: localLogin, error: localError } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // Invite code is always shown but optional
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('[LoginPage] handleSubmit called');
    setLoading(true);
    setError(null);

    try {
      // First attempt regular login
      if (!inviteCode) {
        console.log('[LoginPage] Attempting regular login');
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password })
        });

        if (res.status === 403) {
          // Account exists but needs activation
          // User needs to provide invite code
          setError('Please enter your invite code to activate your account');
          setLoading(false);
          return;
        }

        if (!res.ok) {
          const errData = await res.json().catch(() => ({}));
          throw new Error(errData.error || 'Failed to login');
        }

        const data = await res.json();
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        window.location.href = '/';
        return;
      }

      // If invite code is provided, attempt registration/activation
      if (inviteCode) {
        console.log('[LoginPage] Attempting registration with invite code');
        const url = `${process.env.REACT_APP_API_URL}/api/register`;
        console.log('[LoginPage] Registration URL:', url);
        
        const registerRes = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: email,
            password: password,
            inviteCode: inviteCode
          }),
        });

        // Log the raw response for debugging
        const responseText = await registerRes.text();
        console.log('[LoginPage] Raw registration response:', responseText);

        let data;
        try {
          data = JSON.parse(responseText);
        } catch (jsonError) {
          console.error('[LoginPage] Failed to parse JSON:', jsonError);
          throw new Error('Invalid response from server');
        }

        console.log('[LoginPage] Parsed registration data:', data);

        if (!registerRes.ok) {
          console.error('[LoginPage] Registration failed:', data.error);
          throw new Error(data.error || 'Registration failed');
        }

        // After successful registration, attempt proper DB login
        console.log('[LoginPage] Registration successful, attempting DB login');
        const loginRes = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            email: email, 
            password: password 
          })
        });

        if (!loginRes.ok) {
          throw new Error('Account created but login failed. Please try logging in.');
        }

        const loginData = await loginRes.json();
        localStorage.setItem('token', loginData.token);
        localStorage.setItem('user', JSON.stringify(loginData.user));
        window.location.href = '/';
      }
    } catch (error) {
      console.error('[LoginPage] Error:', error.message);
      
      if (!inviteCode) {
        // Try local login as fallback for regular login
        const localSuccess = await localLogin({ email, password });
        if (localSuccess) {
          window.location.href = '/';
          return;
        }
      }
      
      setError(error.message || 'Authentication failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Paper
          elevation={3}
          sx={{
            mt: isMobile ? 4 : 8,
            p: isMobile ? 2 : 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: 'background.paper',
          }}
        >
          <Box sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img
              src={PaperCutLogo}
              alt="PaperCut Logo"
              style={{ maxWidth: '80%', height: 'auto' }}
            />
          </Box>

          <Typography
            component="h1"
            variant="body1"
            align="center"
            gutterBottom
            color="text.primary"
            sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 2 }}
          >
            Papercut is an AI-powered paper-edit tool that{' '}
            <Box component="span" fontWeight="bold">
              helps TV and documentary post-production teams work with transcripts
            </Box>{' '}
            faster than ever before.
          </Typography>

          <Box component="form" noValidate sx={{ mt: 1, width: '100%' }} onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{
                style: { color: theme.palette.text.secondary },
              }}
              InputProps={{
                style: { color: theme.palette.text.primary, borderColor: '#ddd' },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText="Please remember your password - there is no password reset in beta"
              InputLabelProps={{
                style: { color: theme.palette.text.secondary },
              }}
              InputProps={{
                style: { color: theme.palette.text.primary, borderColor: '#ddd' },
              }}
            />
            
            <TextField
                margin="normal"
                fullWidth
                name="inviteCode"
                label="Invite Code (optional)"
                id="inviteCode"
                value={inviteCode}
                onChange={(e) => setInviteCode(e.target.value)}
                helperText="Required for first-time activation"
                InputLabelProps={{
                  style: { color: theme.palette.text.secondary },
                }}
                InputProps={{
                  style: { color: theme.palette.text.primary, borderColor: '#ddd' },
                }}
              />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: 'text.primary',
                color: 'background.paper',
                borderRadius: '8px',
                '&:hover': { bgcolor: '#444444' },
                textTransform: 'uppercase',
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                inviteCode ? 'Activate Account' : 'Sign In'
              )}
            </Button>
            
            {(error || localError) && (
              <Typography color="error" align="center" sx={{ mt: 1 }}>
                {error || localError}
              </Typography>
            )}

        
          </Box>

          <Divider sx={{ my: 3, width: '100%', bgcolor: 'text.secondary' }} />

          <Typography variant="body2" color="text.secondary" align="center">
            PaperCut is currently in closed beta. If you work with transcripts and want to give it a try,{' '}
            <Link
              href="https://forms.gle/Uzj7wdHCBfmhdD7JA"
              target="_blank"
              rel="noopener"
              color="text.primary"
              sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
            >
              sign up here
            </Link>
            , and we'll be in touch.
          </Typography>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;